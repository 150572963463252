import React from "react";
import Banner from "./Banner";
import Section from "../section/Section";
import Text from "../text/Text";
import Spacer from "../structure/spacer/Spacer";
import Icon from "../icon/icon/Icon";
import IconList from "../icon/icon-list/IconList";
import Button from "../button/Button";
import HideOnMobile from "../structure/hide-on-mobile/HideOnMobile";
import VerticalSlideAnimation from "../../animations/VerticalSliderAnimation";
import Grid from "../grid/Grid";
import GridItem from "../grid/GridItem";
import Container from "../container/Container";

function BannerRegister() {
  const renderBanner = () => {
    return (
      <Banner
        paralax
        image="/assets/image/01-Home-Page/paralax/empower-your-business-background.jpg"
        height="auto"
      >
        <div className="banner-left">
          <Text
            type="h2"
            text="Empower your business. From as little as R385 (incl. VAT) p/month, become an Accountability Member today."
            className="white"
          />

          <Spacer />

          <IconList willStackMedium>
            <Icon>
              <img src="/assets/image/icons/white/right-mark.svg" alt="" />
              <VerticalSlideAnimation className="vertical-slider-three">
                <span>Free Form Downloads</span>
                <span>Security Alerts</span>
                <span>Advisory Services</span>
                <span>Free Form Downloads</span>
                <span>Security Alerts</span>
                <span>Advisory Services</span>
              </VerticalSlideAnimation>
            </Icon>

            <HideOnMobile>
              <Spacer />
            </HideOnMobile>

            <Icon>
              <img src="/assets/image/icons/white/right-mark.svg" alt="" />
              <VerticalSlideAnimation className="vertical-slider-three">
                <span> Consumer Reports</span>
                <span>Qualification Verifications</span>
                <span>Deed Searches</span>
                <span> Consumer Reports</span>
                <span>Qualification Verifications</span>
                <span>Deed Searches</span>
              </VerticalSlideAnimation>
            </Icon>

            <HideOnMobile>
              <Spacer />
            </HideOnMobile>

            <Icon>
              <img src="/assets/image/icons/white/right-mark.svg" alt="" />
              <VerticalSlideAnimation className="vertical-slider-three">
                <span>Consumer Trace</span>
                <span>Secretarial Services</span>
                <span>Bank Code Reports</span>
                <span>Consumer Trace</span>
                <span>Secretarial Services</span>
                <span>Bank Code Reports</span>
              </VerticalSlideAnimation>
            </Icon>
          </IconList>

          <Spacer />

          <Button
            type="redirect"
            text="REGISTER"
            theme="green"
            url="/register"
            classNames={["m-r-15", "m-t-10"]}
          />
        </div>

        <HideOnMobile>
          <div className="banner-right">
            <img
              src="/assets/image/people/Lady.png"
              alt=""
              style={{
                position: "absolute",
                maxWidth: "323px",
                bottom: "-60px",
                right: 0
              }}
            />
          </div>
        </HideOnMobile>
      </Banner>
    );
  };

  const renderBannerIpad = () => {
    return (
      <div
        style={{
          padding: "60px 0",
          background:
            'url("/assets/image/banners-optimized/register-tablet-background.jpg")',
          backgroundPosition: "center",
          backgroundSize: "cover"
        }}
      >
        <Container>
          <Grid>
            <GridItem classNames={["col-md-12"]}>
              <Text
                type="h2"
                text="Empower your business, become an Accountability Member today. From as little as R385.00 (incl. VAT) p/month."
                className="white"
              />
            </GridItem>

            <GridItem classNames={["col-md-12"]}>
              <IconList willStackMedium>
                <Icon>
                  <img src="/assets/image/icons/white/right-mark.svg" alt="" />
                  <VerticalSlideAnimation className="vertical-slider-three">
                    <span>Free Form Downloads</span>
                    <span>Security Alerts</span>
                    <span>Advisory Services</span>
                    <span>Free Form Downloads</span>
                    <span>Security Alerts</span>
                    <span>Advisory Services</span>
                  </VerticalSlideAnimation>
                </Icon>

                <HideOnMobile>
                  <Spacer />
                </HideOnMobile>

                <Icon>
                  <img src="/assets/image/icons/white/right-mark.svg" alt="" />
                  <VerticalSlideAnimation className="vertical-slider-three">
                    <span> Consumer Reports</span>
                    <span>Qualification Verifications</span>
                    <span>Deed Searches</span>
                    <span> Consumer Reports</span>
                    <span>Qualification Verifications</span>
                    <span>Deed Searches</span>
                  </VerticalSlideAnimation>
                </Icon>

                <HideOnMobile>
                  <Spacer />
                </HideOnMobile>

                <Icon>
                  <img src="/assets/image/icons/white/right-mark.svg" alt="" />
                  <VerticalSlideAnimation className="vertical-slider-three">
                    <span>Consumer Trace</span>
                    <span>Secretarial Services</span>
                    <span>Bank Code Reports</span>
                    <span>Consumer Trace</span>
                    <span>Secretarial Services</span>
                    <span>Bank Code Reports</span>
                  </VerticalSlideAnimation>
                </Icon>
              </IconList>

              <Spacer />
            </GridItem>

            <GridItem classNames={["col-md-12"]}>
              <Button
                type="redirect"
                text="REGISTER"
                theme="green"
                url="/register"
                classNames={["m-r-15", "m-t-10"]}
              />
            </GridItem>
          </Grid>
        </Container>
      </div>
    );
  };

  return (
    <Section id="register">
      {window.innerWidth < 770 ? renderBannerIpad() : renderBanner()}
    </Section>
  );
}

export default BannerRegister;
