import axios from "axios";
// const LOCAL_URL = "https://admin.accountability.local/api/v2";
 //const STAGE_URL = "https://acc-admin.stg7.optimalonline.co.za/api/v2";
const STAGE_URL = "https://admin.accountability.stageoptimal.co.za/api";
const PRODUCTION_URL = "https://admin.accountability.co.za/api";
 //const LOCAL_URL = "http://127.0.0.1:8000/api";

const LOCAL_KEY =
  "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjY0YTBiNWEzZjFkZTY3YzdhODk5YjVjZTdhMTMwNzUwYTIzZDgxMzA0ZjM5MzU5NTZkN2Y1Nzk4N2NhZWI0OTEyNzMxMzM0ZjA2MDFlNmJjIn0.eyJhdWQiOiIyIiwianRpIjoiNjRhMGI1YTNmMWRlNjdjN2E4OTliNWNlN2ExMzA3NTBhMjNkODEzMDRmMzkzNTk1NmQ3ZjU3OTg3Y2FlYjQ5MTI3MzEzMzRmMDYwMWU2YmMiLCJpYXQiOjE1NzAxOTg2MTQsIm5iZiI6MTU3MDE5ODYxNCwiZXhwIjoxNjAxODIxMDE0LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.NbF6aNkUmQFrbrXi-fb8cNW_7tYUrHvlgN3tzPYwkdqtGF5yXltSxgXOjDXxfdgElb5oYNZuTj9mrX3GXfRzCCGpQtczPlZnpgxCniqPNyA-bdz0JWvF7PA6E-axRR8oZBxwY_xIHDLbPsLMAJjmzfXBlMF_gvnaHSQj1ynqUfBM7ZdRu7WN_353tEfR-7qbFbai-627dWTUjk4z8Y58moKF2h668zRALeWZpxH8_zg9pmx6lmbcADUPlyYO4FZKqVZsIcEajK4uHEJF_vE70NC5Q1ga_JlBkINzQg1tXe-aBN12ISKtjLCAfgTs2CJP3nN0X2umgwzeRDMwQRauNFjEZUQvm0PD8mg4OyzZDf6f7ge0zuj3sccoz0GaDdZZex7o797FQ_IY6T4aZLO0b6EyNbztTpqtwZSDwMUDZ85U2_WEkZC6dWJLnsH6CHTykCzbovWAFh_Na0ak36IEnr62w_GYHRGm-5GBnUu7jb8QHVJuA2s41LiDHwc9hC7gDTgDu2026SHnAtxc06aH0YOb6nkW4mJDQ9FJUf_YYtYcMLXwnIvnIXmWdYvm_CZs7Fzy8Fwui8crpw_wgdMBsLRc-_NfNU6wqpfkZb3HstczP4c3DsAbBm3XU-m_qLn0EgPj51IQQBaiGFnADPn3KGovTG3L9zg2XhMmYndfrCw";
const STAGE_KEY =
  "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImMxYThkNmY0ODk4NDIwNmY2MGMwMWJmNDgyNDE4ZDBmYThmMTM5YThiODI4YjRiMjJhNzU2ZWU5NjAwOTMwOWI1MTFhNzg5MDNkOGQ1MjNhIn0.eyJhdWQiOiIyIiwianRpIjoiYzFhOGQ2ZjQ4OTg0MjA2ZjYwYzAxYmY0ODI0MThkMGZhOGYxMzlhOGI4MjhiNGIyMmE3NTZlZTk2MDA5MzA5YjUxMWE3ODkwM2Q4ZDUyM2EiLCJpYXQiOjE1NzA0MzgxMzMsIm5iZiI6MTU3MDQzODEzMywiZXhwIjoxNjAyMDYwNTMzLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.JRe4lFAcnXEUqmbQzp9qaisz6f-QGa7OoagxAeZqlU9Sg22ExcXDShuOehjFDSKLXf7mI7sD7-W8GLY-3Z6arhpdfF4kXopCBbgcYY6mpEM08KNwCvGzY397AcoCPjYRhzp3H7iMEag1dOfPiYxDpptyUCUhwRo-9OLrIGO9wrXjMREtPhpfyJ-FESiAQ0FPlCx7po9F5gc8vaA_qyqUtQi_LdG7cpcaSXAkp9SfhhvCddDC4u0c_nV5TOjBMTZm6pf0N10A2QG3cTfcwUvXZiyc9_uZ8ZbmAm-kNgIzVfnBF0CNTS3KAGtxiTI_8NwmEGaF4fGcAUfeMNLGcq8c9acuImf13X9aZ83bMzbBFwZ6_HMolRwRyyZDU48nnezwxVvbcmEG7JyOhSqaRtaTsGoeR0fIYid2mIy3ntu4_qyj07ShxuF9qGtuHrDAEP0FhTQFMBaAmKqj26UpHlK7ED-EYIFlZhIMPyF44IVRksplyUvl1I3KvqqVpjGftcWJ1K87WwsNbzZdGrAy74Lf6iu9KKOjg7y_TKys58sxHuBhC9RWFtfd09FRQ5eOIMrp1C6Hi_zSA8y8UycgLzlp6S4seOMzrVB6EDtwJEM1keY5Uw4717I02TR7suG47-5_9orIXgAVpgXPW3AkS13wG5X0yZLVhoOEFMrYXPVhdWA";
// const PRODUCTION_KEY =

export default axios.create({
  baseURL: STAGE_URL,
  // headers: {
  //   Authorization: STAGE_KEY,
  //   Accept: "application/json",
  //   "Content-Type": "application/json"
  // }
});
