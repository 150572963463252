import _ from 'lodash';
import { FETCH_SERVICE, FETCH_SERVICES, CLEAR_SERVICES } from '../actions/types';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_SERVICE:
      return { ...state, [action.slug]: action.payload };
    case FETCH_SERVICES:
      return { ...state, ..._.mapKeys(action.payload, 'slug') };
    case CLEAR_SERVICES:
      return {};
    default:
      return state;
  }
};
