import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import { fetchReportToken } from "../../../actions/index";
import {
  renderInput,
  renderInputWithLabel,
  renderSelect,
  renderCheckbox, renderValueSelect
} from "../renderField";
import Button from "../../ui/button/Button";
import CenteredBox from "../../ui/structure/CenteredBox";
import Section from "../../ui/section/Section";
import SectionHeader from "../../ui/section/SectionHeader";
import Container from "../../ui/container/Container";
import FormContainer from "../../ui/container/form-container/FormContainer";
import Text from "../../ui/text/Text";
import Recaptcha from "react-recaptcha";
import Spacer from "../../ui/structure/spacer/Spacer";
import Loader from "../../ui/loader/Loader";
import scrollToFirstError from '../SmoothScrollToErrorFields.js';

class ReportRegisterFirstPage extends Component {

  constructor(props){
    super(props)
    this.verifyCallbackRecapcha = this.verifyCallbackRecapcha.bind(this);
    this.onloadCallbackRecapcha = this.onloadCallbackRecapcha.bind(this);
    this.state = {
      recapchaIsVerified: false
    }
  }  

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onloadCallbackRecapcha(){
    console.log('recapcha has loaded');
  }

  verifyCallbackRecapcha(response){
    if(response){
      this.setState({
        recapchaIsVerified: true
      }); 
    }      
    this.setState({recapchaIsVerified: true});
    console.log('recapcha has been verified');
  }

  onSubmit = formValues => {
    if(this.state.recapchaIsVerified){
      if(formValues.public_3in1_query_type === "query by registration"){
        formValues.public_3in1_query_type = "RegNo";
      }
      if(formValues.public_3in1_query_type === "query by name"){
        formValues.public_3in1_query_type = "Name";
        formValues.public_3in1_name = formValues.public_3in1_registration;
      }
      console.log(formValues.public_3in1_query_type);
      this.props.fetchReportToken('3in1', formValues, this.props.nextPage);
      window.scrollTo(0, 0);
    } else {
      console.log('recapcha has not been verified');
      alert('Please verify that you are human!');
    }
  };

  render() {
    const { handleSubmit } = this.props;
    const typeOptions = ["Please Select", "Query by Name", "Query by Registration"];
    const reasonOptions = [
      { value: "", name: "Please Select" },
      // { value: "CreditRisk", name: "Credit Risk" },
      // { value: "CreditLimitManagement", name: "Credit Limit Management" },
      // { value: "InsuranceApplication", name: "Insurance Application" },
      // { value: "Employment", name: "Employment" },
      // { value: "FraudCorruptionTheftInv", name: "Fraud Corruption or Theft Investigation" },
      // { value: "FraudDetectionFraudPrev", name: "Fraud Detection and Fraud Prevention" },
      // { value: "SettingLimit", name: "Setting Limit" },
      // { value: "UnclaimedFundsDistribution", name: "Unclaimed Funds Distribution" },
      // { value: "AffordabilityAssessment", name: "Affordability / Financial Means Assessment" },
      // { value: "PrescreeningMarketing", name: "Prescreening / Marketing" },
      // { value: "Other", name: "Other" },
      // { value: "DebtorBookAssessment", name: "Debtor Book Assessment" },
      // { value: "TracingByCreditProvider", name: "Tracing by Credit Provider" },
      // { value: "ModelDevelopment", name: "Model Development" },
      // { value: "Contactibility", name: "Contactibility" },
      // { value: "CreditScoringModel", name: "Credit Scoring Model" },
      // { value: "CreditInformationQuery", name: "Credit Information Query" },
      // { value: "DebtCounseling", name: "Debt Counseling" },
      { value: "CreditApplication", name: "Credit Application" },
      // { value: "UpdateRecords", name: "Update Records" },
      // { value: "Supplier", name: "Supplier" },
      // { value: "DefaultCheck", name: "Default Check" },
      // { value: "FactualCheck", name: "Factual Check" },
      // { value: "ReferanceCheck", name: "Reference Check" },
      // { value: "Tracing", name: "Tracing" },
      // { value: "Lease", name: "Lease" },
      // { value: "Rental", name: "Rental" },
      // { value: "Surety", name: "Surety" },
      // { value: "InternalEnquiry", name: "Internal Enquiry" },
      // { value: "Marketing", name: "Marketing" },
      // { value: "UnknownCPUtoCPU", name: "Unknown CPU to CPU" },
      // { value: "InternationalEnquiry", name: "International Enquiry" },
      // { value: "SubscriberConcern", name: "Subscriber Concern" },
      // { value: "AccessCheck", name: "Access Check" },
      // { value: "InsuranceClaim", name: "Insurance Claim" },
      // { value: "EmploymentCheck", name: "Employment and/or Educational Check" },
      // { value: "FraudInvestigation", name: "Fraud Corruption or Theft Investigation" },
      // { value: "FraudDetection", name: "Fraud Detection" },
      // { value: "ProvisionLimit", name: "Provision Limit" },
      // { value: "CreditScoreDevelopment", name: "Credit Score Development" },
      // { value: "Affordability", name: "Affordability" },
      // { value: "PropensityToRepay", name: "Propensity to Repay" }
    ];

    if (this.props.isLoading) {
      return <Loader halfscreen />;
    }

    return (
      <React.Fragment>
        <Container>
          <Section id="section-register">
            <SectionHeader noPadding autoWidth title="Registration" />
            <Text
              type="p"
              className="centered"
              text="The Comprehensive 3-in-1 Commercial Credit Report offers a thorough assessment of a business's creditworthiness by combining data from Inoxico, TransUnion, and XDS. This enables companies to gain an in-depth understanding of the financial and operational aspects of a potential partner or client, assisting with enabling informed decision-making and risk assessment."
            />
            <Text
              type="p"
              className="centered"
              text="The cost per report is <span class='color-green bold'>R970.99</span> (incl. VAT)."
            />
          </Section>
        </Container>

        <Section>
          <SectionHeader noPadding autoWidth title="Search Details" />

          <form onSubmit={handleSubmit(this.onSubmit)}>
            <FormContainer>
              {'public_3in1_registration' && <div name={`position-public_3in1_registration`} />}
              <Field
                name="public_3in1_registration"
                type="text"
                component={renderInputWithLabel}
                label="Business Name OR Registration Number *"
                note="Please include the relevant slashes. (/) when entering the registration number. Eg. 2008/012163/07"
              />

              {'public_3in1_query_type' && <div name={`position-public_3in1_query_type`} />}
              <Field
                name="public_3in1_query_type"
                type="text"
                component={renderSelect}
                label="Enquiry Type *"
                options={typeOptions}
              />
              {'public_3in1_reason' && <div name={`position-public_3in1_reason`} />}
              <Field
                  name="public_3in1_reason"
                  type="text"
                  component={renderValueSelect}
                  label="Reason for Enquiry *"
                  options={reasonOptions}
              />
              {'public_3in1_requester' && <div name={`position-public_3in1_requester`} />}
              <Field
                name="public_3in1_requester"
                type="text"
                component={renderInputWithLabel}
                label="Name and Surname of Requester *"
              />
              {'public_3in1_email' && <div name={`position-public_3in1_email`} />}
              <Field
                name="public_3in1_email"
                type="email"
                component={renderInputWithLabel}
                label="Email Address Of Requester *"
                note="Please enter the email address where the report will be sent."
              />
              {'email_address_confirmation' && <div name={`position-email_address_confirmation`} />}
              <Field
                name="email_address_confirmation"
                type="email"
                component={renderInputWithLabel}
                label="Confirm Email Address *"
              />
            </FormContainer>

            <Container>
              <Spacer size="120" />
              <article>
                <Text type="h3" className="default" text="Please Note:" />
                <Text
                  type="p"
                  text="For each successful enquiry, a fee of <b>R970.99 (incl. VAT)</b> will apply."
                />
                <Text
                    type="p"
                    text="Exclusively for Accountability Members, our comprehensive report is now available at a discounted rate of <b>R585.99 (incl. VAT).</b>"
                />
                <Text
                    type="p"
                    text="This essential resource provides all the critical information your business needs to perform thorough checks at a minimal cost."
                />
                <Text
                    type="p"
                    text="Not a Member Yet?<br/>Join Accountability today to take advantage of this exclusive offer and elevate your business's due diligence processes."
                />
                <Button
                    type="redirect"
                    text="REGISTER"
                    theme="green"
                    url="/register"
                ></Button>
                <Spacer size="20" />
                <Text
                  type="p"
                  text="The fields marked with a * are mandatory and you will not be able to
                  perform the search without them."
                />
                <Text
                  type="h3"
                  className="default"
                  text="Terms of Regulation:"
                />
                <Text
                  type="p"
                  text="This report contains information from public records that Accountability Group (Pty) Ltd does not have control of and has not been verified unless otherwise indicated in the issued report. The reports are subject to Accountability’s Terms and Conditions available <a style='text-decoration: underline' href='/terms-and-conditions' target='_blank'>here.</a>"
                />
                <Text
                  type="p"
                  text="By submitting this request, you also confirm and warrant that;"
                />
                <ul className="mission-ul">
                  <li style={{display: 'flex'}}>
                    <p style={{marginRight: '5px'}}>(a)</p> <p>you comply with the requirements set out in the Protection of Personal Information Act, 4 of 2013 and its Regulations;</p>
                  </li>
                  <li style={{display: 'flex'}}>
                    <p style={{marginRight: '5px'}}>(b)</p> <p> you have obtained the relevant consent from your clients for the collection, collation, processing, and storing of such information by Accountability; and</p>
                  </li>
                  <li style={{display: 'flex'}}>
                    <p style={{marginRight: '5px'}}>(c)</p> <p> that your clients are aware that the purpose of the processing will relate to the services Accountability render and may also include placing such information on the relevant Credit Bureaus which would, in effect, be available in the public domain.</p>
                  </li>
                </ul>
                <Text
                  type="p"
                  text="Although not specifically required by the provisions of Regulation 18(5) of the National Credit Regulations, which requires the written consent of a consumer to be obtained before a credit report on that person may be drawn, it remains advisable for you to, in writing, pre-warn clients that are juristic persons that a Commercial Credit Report will be performed on their company. Such pre-warning may be incorporated into your standard Terms and Conditions of trade."
                />
                {'agree_terms' && <div name={`position-agree_terms`} />}
                <Field
                  name="agree_terms"
                  type="checkbox"
                  component={renderCheckbox}
                  label="<span style='font-family: montserratregular; font-size: 16px; letter-spacing: 0.3px;'>I agree to the Terms of Regulation as stated above *</span>"
                  noMargin
                />
              </article>

              <Spacer size="60" />
              <CenteredBox noPadding stacked>
                <Recaptcha 
                  sitekey="6LfL17kUAAAAAOLB-BxODBlF9tIQCpESslxA_lLj"
                  render="explicit"
                  onloadCallback={this.onloadCallbackRecapcha}
                  verifyCallback={this.verifyCallbackRecapcha}
                /> 
                <Button
                  type="submit"
                  classNames={["next", "centered", "large"]}
                  theme="green"
                  text="NEXT"
                >
                  Next
                </Button>
              </CenteredBox>
            </Container>
          </form>
        </Section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.isLoading
  };
};

const formWrapped = reduxForm({
  form: "wizard", // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(ReportRegisterFirstPage);

export default connect(mapStateToProps, { fetchReportToken })(formWrapped);
