import "./ServiceCard.scss";
import React from "react";
import Text from "../../text/Text";
import Button from "../../button/Button";
import { Link } from "react-router-dom";

export default function ServiceCard({ title, text, image, url }) {
  const renderImage = () => {
    return (
      image && (
        <div className="preview-image">
          <img src={image} alt={title} style={{border: "1px solid red"}}/>
        </div>
      )
    );
  };

  const renderText = () => {
    return text;
  };


  return (
    <div className="service-card">
      <Link to={url}>{renderImage()}</Link>
      <div className="preview-card-content">
        <Link to={url}>
          <Text type="h3" text={title} />
          <Text type="p" text={renderText()} />
        </Link>
        <a href={url} className={"service-url"}>Learn More</a>

      </div>
    </div>
  );
}
