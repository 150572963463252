import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { clearServices, fetchCategoriesService, fetchCategory } from "../../../actions/index";
import BannerHeader from "../../ui/banner/BannerHeader";
import BannerRegister from "../../ui/banner/BannerRegister";
import Section from "../../ui/section/Section";
import SectionHeader from "../../ui/section/SectionHeader";
import Grid from "../../ui/grid/Grid";
import GridItem from "../../ui/grid/GridItem";
import ServiceCard from "../../ui/card/service-card/ServiceCard";
import Container from "../../ui/container/Container";
import Loader from "../../ui/loader/Loader";
import { apiImagePath, chunk } from "../../../utils/functions";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import FormContact from "../../forms/FormContact";

class ServicesCategories extends Component {
  componentDidMount() {
    const { slug } = this.props.match.params;
  
    console.log("Clearing services...");
    this.props.clearServices();
  
    setTimeout(() => {
      console.log("Fetching category and services...");
      this.props.fetchCategory(slug);
      this.props.fetchCategoriesService(slug);
    }, 500); // Delay to check if clear works
  }
  
    renderServiceChunks = () => {
      console.log(this.props)
      const { services, category } = this.props;
  
      if (_.isEmpty(services)) {
        return <Loader />;
      }
  
      // Filter out services where the preview_title matches the category title
      const filteredServices = services.filter(
        (service) => service.preview_title !== category?.preview_title
      );
  
      const serviceChunks = chunk(filteredServices.slice(1, 10), 3);
  
      console.log(serviceChunks);
      return serviceChunks.map((chunk, index) => (
        <Grid key={index} classNames={["d-flex", "justify-content-center", "flex-wrap", "w-100"]}>
          {this.renderServices(chunk)}
        </Grid>
      ));
    };
  
    renderServices = (chunk) => {
      console.log("This is called")
      return chunk.map((service) => {
        let serviceUrl = `/services/${this.props.match.params.slug}/${service.slug}`;
  
        return (
          <GridItem key={service.id} classNames={["d-flex", "justify-content-center", "col-lg-4 col-md-6 col-sm-12"]}>
            <ServiceCard
              title={service.preview_title}
              text={service.preview_content}
              url={serviceUrl}
            />
          </GridItem>
        );
      });
    };
  
    renderPageContent() {
      const { services = [], category } = this.props; // Ensure services is at least an empty array
      const serviceTitle =
        services.length > 0 && services[0]?.preview_title
          ? services[0].preview_title
          : category?.title || "Services"; // Fallback to category title or "Services"
  
  
      return (
        <React.Fragment>
          <BannerHeader
            image="/assets/image/banners-optimized/services-banner@2x-compressor.jpg"
            title="Services"
          >
            <p className="white breadcrumb">
              <Link to="/">Home</Link> | <Link to="/services">Services</Link>
            </p>
          </BannerHeader>
  
          <Section id="section-services">
            <SectionHeader
              title={serviceTitle}
            />
  
            <div style={{paddingBottom: '100px'}}><Container>{this.renderServiceChunks()}</Container></div>
          </Section>
  
          <BannerRegister />
        </React.Fragment>
      );
    }
  
    render() {
      return (
        <div className="services">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Services | Accountability Group Pty Ltd</title>
            <meta
              name="description"
              content="In-depth reports and specialised services at your fingertips. From Consumer and Commercial reports to debt collection facilitation, Accountability offers its Members a full spectrum of services which are available on our website, 24/7."
            />
            <link rel="canonical" href={window.location.href} />
          </Helmet>
  
          {this.renderPageContent()}
          <Section>
          <SectionHeader noPadding title="Get In Touch With Us" />
          <FormContact />
        </Section>
        </div>
      );
    }
  }
  
  const mapStateToProps = (state) => ({
    services: Object.values(state.services),
    category: state.category, // Category is now used for filtering
  });
  
  export default connect(mapStateToProps, { fetchCategoriesService, fetchCategory, clearServices })(ServicesCategories);
  