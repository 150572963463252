import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchService } from "../../../actions/index";
import { apiImagePath, deviceImageSwap, cleanText } from "../../../utils/functions";
import ReactHtmlParser from "react-html-parser";
import Gallery from "../../ui/custom-slider/Gallery";
import SliderItem from "../../ui/custom-slider/SliderItem";
import SliderContent from "../../ui/custom-slider/SliderContent";
import Text from "../../ui/text/Text";
import Button from "../../ui/button/Button";
import ButtonList from "../../ui/button/ButtonList";
import Container from "../../ui/container/Container";
import Section from "../../ui/section/Section";
import SectionHeader from "../../ui/section/SectionHeader";
import CenteredBox from "../../ui/structure/CenteredBox";
import FormService from "../../forms/FormService";
import Loader from "../../ui/loader/Loader";
import FullHeight from "../../ui/structure/FullHeight";
import { Helmet } from "react-helmet";
import history from "../../../utils/history/history";
import Popup from "../../ui/popup/Popup";

class Service extends Component {
  constructor(props) {
    super(props);
    this.sectionServices = React.createRef();
  }

  componentDidMount() {
    const slug  = this.props.match.params.secondary;

    this.props.fetchService(slug);
  }

  handleScrollDown = () => {
    this.sectionServices.current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  };

  renderGallery = () => {
    const { service } = this.props;
    if (!service) {
      console.log("Service data is not available yet, showing loader.");
      return <Loader fullscreen />;
    }

    const { main_title, main_image, mobile_image } = service;
    const image = deviceImageSwap(mobile_image, main_image);
    const headerItems = [
      {
        image: apiImagePath(image),
        title: main_title
      }
    ];

    return <Gallery disableAll>{this.renderGalleryItems(headerItems)}</Gallery>;
  };

  renderGalleryItems = headerItems => {
    return headerItems.map(({ image, title }, i) => {
      return (
        <SliderItem scrollDown={this.handleScrollDown} key={i} image={image}>
          <SliderContent>
            <Text type="h2" text={title} />
            <ButtonList>
              <Button
                type="scroll"
                text="Get In Touch"
                theme="green"
                onClick={this.handleScrollDown}
              />
            </ButtonList>
          </SliderContent>
        </SliderItem>
      );
    });
  };

  renderReportButton = () => {
    if (
      this.props.match.params.slug ===
      "comprehensive-4-in-1-consumer-credit-report"
    ) {
      return (
        <CenteredBox noPadding>
          <Button
            type="redirect"
            theme="yellow"
            text="REQUEST YOUR REPORT HERE"
            url={
              "/services/comprehensive-4-in-1-consumer-credit-report/register"
            }
          />
        </CenteredBox>
      );
    }
    if (
      this.props.match.params.slug ===
      "comprehensive-3-in-1-commercial-credit-report"
    ) {
      return (
        <CenteredBox noPadding>
          <Button
            type="redirect"
            theme="yellow"
            text="REQUEST YOUR REPORT HERE"
            url={
              "/services/comprehensive-3-in-1-commercial-credit-report/register"
            }
          />
        </CenteredBox>
      );
    }
  };

  renderSubText = () => {
    const  slug  = this.props.match.params.secondary;
    if (
      slug === "debt-collection-facilitation" ||
      slug === "default-listings" ||
      slug === "judgement-removals" ||
      slug === "corporate-training" ||
      slug === "commercial-credit-insurance" ||
      slug === "secretarial-services"
    ) {
      return (
        <Text
          type="p"
          className="centered p-t-50"
          text="Accountability Solutions (Pty) Ltd is the dominant web-based service team that aims to protect your business by providing you with the tools to report consumer and commercial 
          credit behaviour to registered Credit Bureaus. By becoming a valued Member of Accountability, you will have access to a variety of services,
           as well as the real-life support of our highly-trained team, that will assist you in making default payments a thing of the past."
        />
      );
    } else {
      return (
        <Text
          type="p"
          className="centered p-t-50"
          text="Accountability is the dominant web-based service team that aims to protect your business by providing you with
          the tools to reduce your credit risk. By becoming a valued Member of Accountability, you will have access 
          to a variety of services, as well as the real-life support of our highly-trained team, that will assist 
          you in making credit risk a thing of the past."
        />
      );
    }
  };

  renderService = () => {
    const { service, match } = this.props;
    

    if(match.params.slug === 'id-verification'){
      window.location.href = '/services/id-verification-with-photo';
    }

    if (service === null) {
      console.log("Service not found, redirecting to services list.");
      window.location.href = '/services';
      return;
    }

    if (!service) {
      console.log("Service is still loading, showing loader.");
      return <Loader />;
    }

    const { main_title, preview_title, main_content, meta_description, preview_image, created_at, form_heading } = service;
    const preview_title_cleaned = cleanText(preview_title);

    const ldJson = {
      "@context": "https://schema.org",
      "@type": "Article",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": window.location.href
      },
      "headline": preview_title_cleaned,
      "description": meta_description,
      "image": apiImagePath(preview_image),  
      "author": {
        "@type": "Organization",
        "name": "Accountability"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Optimal Online",
        "logo": {
          "@type": "ImageObject",
          "url": "https://www.optimalonline.co.za/images/optimal-online-logo.png"
        }
      },
      "datePublished": created_at.split(' ')[0]
    };

    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{main_title} | Accountability</title>
          <meta name="description" content={meta_description} />
          <link rel="canonical" href={window.location} />
          <script type="application/ld+json">
              {JSON.stringify(ldJson)}
          </script>
        </Helmet>

        <Section id="service-content">
          {ReactHtmlParser(main_content)}

          <div style={{ marginTop: '40px'}}>
            {this.renderReportButton()}
          </div>
        </Section>
        <Section id="service-form">
          <SectionHeader
            subtitle={form_heading ? form_heading : "Complete the form below and one of our consultants will contact you shortly."}
            noPadding
            autoWidth
          />

          <FormService subject={main_title} />

          {this.renderSubText()}
        </Section>
      </React.Fragment>
    );
  };

  handleGoBack = () => {
    this.props.history.goBack();
  };
  
  render() {
    return (
      <>
        {history.location.pathname === "/services/bank-code-reports" && <Popup />}
        <div className="service">
          <FullHeight>{this.renderGallery()}</FullHeight>
          <div ref={this.sectionServices}></div>
          <Container>
            {this.renderService()}{" "}
            <CenteredBox>
              <Button theme="green" text="BACK" onClick={this.handleGoBack} />
            </CenteredBox>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const  slug = ownProps.match.params.secondary;
  console.log("Current service state:", state.services[slug]);
  return {
    service: state.services[slug]
  };
};

export default connect(mapStateToProps, { fetchService })(Service);
