import "./HomeServicesCard.scss";
import React from "react";
import Text from "../../text/Text";
import { textTruncate } from "../../../../utils/functions";
import { Link } from "react-router-dom";

export default function HomeServiceCard({ title, text, image, truncated, url }) {
  const renderImage = () => {
    return (
      image && (
        <div className="home-service-image">
          <img src={image} alt={title} />
        </div>
      )
    );
  };

  const renderText = () => {
    return truncated ? textTruncate(text, 150, "...") : text;
  };

  return (
    <div className="home-service-container">
      <div className="home-service-card">
        <Link to={url}>{renderImage()}</Link>

        <div className="home-service-card-content">
          <Link to={url}>
            <Text type="h3" text={title} />
            <Text type="p" text={renderText()} />
          </Link>
          <a className="preview-url" href={url} style={{ width: "100%" }}>
            <div
              style={{
                paddingBottom: "10px",
                borderBottom: "1px solid #e0e0e0",
                display: "flex",
              }}
            >
              <div style={{ marginRight: "15px" }}>Learn More</div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.208"
                  height="11.414"
                  viewBox="0 0 11.208 11.414"
                >
                  <g id="Group_644" data-name="Group 644" transform="translate(-544.5 -1684.793)">
                    <path
                      id="Path_749"
                      data-name="Path 749"
                      d="M-8042.02-12880h10"
                      transform="translate(8587.02 14570.497)"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_750"
                      data-name="Path 750"
                      d="M-8031.152-12884.916l5,5-5,5"
                      transform="translate(8581.152 14570.416)"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
