import history from "../utils/history/history";
import {
  SHOW_FEEDBACK,
  FETCH_SOCIAL_FEED,
  UPDATE_SOCIAL_FEED,
  FETCH_ACCOUNT_TYPES,
  FETCH_INSTITUTIONS,
  // LOGIN,
  REGISTER,
  FETCH_DOCUMENTATION,
  FETCH_MEMBER_REFERENCE,
  FETCH_TESTIMONIALS,
  FETCH_SERVICES,
  FETCH_SERVICE,
  FETCH_NEWS_ARTICLES,
  FETCH_NEWS_ARTICLE,
  FETCH_MEMBER_DETAILS,
  VERIFY_AGE,
  FETCH_AGENTS,
  IS_LOADING,
  FETCH_4_IN_1_TOKEN,
  FETCH_4_IN_1_QUESTIONS,
  CREATE_4_IN_1_PAYMENT,
  FETCH_3_IN_1_TOKEN,
  FETCH_3_IN_1_RESULTS,
  CREATE_3_IN_1_PAYMENT,
  GENERATE_REPORT,
  SHOW_MODAL,
  HIDE_MODAL,
  CREATE_PAYFAST_FORM,
  REMOVE_QUESTIONS,
  CREATE_REGISTER_PAYMENT,
  VERIFY_PAYMENT_TYPE,
  FETCH_PAGE, UPDATE_3_IN_1_PAYMENT,
  FETCH_SERVICE_CATEGORIES,
  CLEAR_SERVICES
} from "./types";
import accountability from "../api/accountability";
import admin from "../api/admin";
import flocker from "../api/flocker";
import { fetchLocalStorage } from "../utils/functions";
// import validate from "../components/forms/4-in-1-report/validate";
// import { reduxForm } from "redux-form";


export const throwError = message => async dispatch => {
  dispatch({
    type: SHOW_FEEDBACK,
    payload: {
      message: message
    }
  });
};

export const showModal = id => async dispatch => {
  dispatch({
    type: SHOW_MODAL,
    payload: {
      isActive: true,
      id: id
    }
  });
};

export const hideModal = id => async dispatch => {
  dispatch({
    type: HIDE_MODAL,
    payload: {
      isActive: false,
      id: id
    }
  });
};

export const isLoading = loading => async dispatch => {
  dispatch({
    type: IS_LOADING,
    payload: loading
  });
};

export const fetchSocialFeed = () => async dispatch => {
  const response = await flocker.get(`/8288/articles`, {
    params: {
      count: 8
    }
  });

  dispatch({
    type: FETCH_SOCIAL_FEED,
    payload: response.data
  });
};

export const clearServices = () => {
  return {
    type: CLEAR_SERVICES
  };
};

export const fetchServices = () => async dispatch => {
  // First dispatch the clear action
  dispatch(clearServices());
  
  const response = await admin.get(`/services`);

  dispatch({
    type: FETCH_SERVICES,
    payload: response.data.data
  });
};

// export const fetchServicesList = () => async dispatch => {
//   const response = await admin.get(`/services/list`);

//   dispatch({
//     type: FETCH_SERVICES,
//     payload: response.data.data
//   });
// };

export const fetchCategoriesService = category => async dispatch => {
  const response = await admin.get(`/services/${category}`);

  console.log(response.data.data)

  dispatch({
    type: FETCH_SERVICES,
    payload: response.data.data
  });
};

export const fetchCategory = category => async dispatch => {
  const response = await admin.get(`/category/${category}`);

  dispatch({
    type: FETCH_SERVICE,
    payload: response.data.data
  });
};

export const fetchService = (slug) => async (dispatch) => {
  try {
    // Make the API call to fetch the service data
    const response = await admin.get(`/service/${slug}`);

    // Check if the response is successful and contains data
    if (response.status === 200 && response.data && response.data.data) {
  

      // Dispatch the data to Redux store
      dispatch({
        type: FETCH_SERVICE,
        payload: response.data.data,
        slug
      });

      // Return the fetched data
      return response.data.data;
    } else {
      console.error("Failed to fetch service data, no valid data received");
      return null;
    }
  } catch (error) {
    console.error("Error fetching service data:", error);
    // Return null in case of error
    return null;
  }
};


export const fetchTestimonials = () => async dispatch => {
  const response = await admin.get(`/testimonials`);

  dispatch({
    type: FETCH_TESTIMONIALS,
    payload: response.data.data
  });
};

export const fetchNewsArticles = () => async dispatch => {
  const response = await admin.get(`/news`);

  dispatch({
    type: FETCH_NEWS_ARTICLES,
    payload: response.data.data
  });
};

export const fetchNewsArticle = slug => async dispatch => {
  const response = await admin.get(`/news/${slug}`);

  dispatch({
    type: FETCH_NEWS_ARTICLE,
    payload: response.data.data,
    slug
  });
};

export const fetchPage = slug => async dispatch => {
  
  const response = await admin.get(`/pages/${slug}`);

  dispatch({
    type: FETCH_PAGE,
    payload: response.data.data,
    slug
  });
};

export const updateSocialFeed = url => async dispatch => {
  const response = await flocker.get(`${url}`);

  dispatch({
    type: UPDATE_SOCIAL_FEED,
    payload: response.data
  });
};

export const fetchAccountTypes = () => async dispatch => {
  const data = {
    lookup: "register.account_type"
  };

  const response = await accountability.post(
    "/index.php?c=rest/public/v1/lookup",
    data
  );

  dispatch({
    type: FETCH_ACCOUNT_TYPES,
    payload: response.data.data["register.account_type"]
  });
};

export const fetchInstitutions = () => async dispatch => {
  const data = {
    lookup: "register.bank"
  };

  const response = await accountability.post(
    "/index.php?c=rest/public/v1/lookup",
    data
  );

  dispatch({
    type: FETCH_INSTITUTIONS,
    payload: response.data.data["register.bank"]
  });
};

export const verifyBankAccount = formValues => async dispatch => {
  const response = await accountability.post(
    "/index.php?c=rest/public/v1/verify_bank_account",
    formValues
  );
};

export const login = formValues => async dispatch => {
  const response = await accountability.post(
    "index.php?c=index/x_mobile_group_login_web",
    formValues
  );
};

export const forgotPassword = formValues => async dispatch => {
  const response = await accountability.post(
    "index.php?c=index/x_reset_group_request",
    formValues
  );
};

export const register = formValues => async dispatch => {
  const response = await accountability.post(
    "index.php?c=rest/public/v1/register",
    formValues
  );

  localStorage.setItem("userTokens", JSON.stringify(response.data.data));

  dispatch({
    type: REGISTER,
    payload: response.data.data
  });
};

export const createRegisterPayment = token => async dispatch => {
  try {
    const data = {
      registration_token: token
    };

    const response = await accountability.post(
      "index.php?c=rest/public/v1/register_payment_create",
      data
    );

    localStorage.setItem(
      "userPaymentTokens",
      JSON.stringify(response.data.data)
    );

    dispatch({
      type: CREATE_REGISTER_PAYMENT,
      payload: response.data.data
    });
  } catch (error) {
    dispatch({
      type: SHOW_FEEDBACK,
      payload: error
    });
  }
};

export const fetchMemberReference = registrationToken => async dispatch => {
  dispatch({
    type: IS_LOADING,
    payload: true
  });  

  try {
    const data = {
      registration_token: registrationToken
    };

    const response = await accountability.post(
      "index.php?c=rest/public/v1/member_reference",
      data
    );

    let lsu = {
      first_name: response.data.data.company_name,
      email_address: response.data.data.company_email
    };

    localStorage.setItem("userDetails", JSON.stringify(lsu));

    dispatch({
      type: FETCH_MEMBER_REFERENCE,
      payload: response.data.data
    });
  } catch (error) {
    dispatch({
      type: SHOW_FEEDBACK,
      payload: error
    });
  }

  dispatch({
    type: IS_LOADING,
    payload: false
  });
};

// export const fetchAgents = () => async dispatch => {
//   const data = {
//     lookup: "agents_array"
//   };

//   const response = await accountability.post(
//     "/index.php?c=rest/public/v1/agent_lookup",
//     data
//   );

//   dispatch({
//     type: FETCH_AGENTS,
//     payload: response.data.data
//   });
// };

export const fetchMemberDetails = registration_token => async dispatch => {
  const data = {
    registration_token: registration_token
  };

  const response = await accountability.post(
    "/index.php?c=rest/public/v1/member_details",
    data
  );

  console.log("member details", response.data.data);

  dispatch({
    type: FETCH_MEMBER_DETAILS,
    payload: response.data.data
  });
};

export const fetchDocumentation = () => async dispatch => {
  dispatch({
    type: IS_LOADING,
    payload: true
  });

  try {
    const userTokens = fetchLocalStorage("userTokens");
    const data = {
      registration_token: userTokens.registration_token
    };

    const response = await accountability.post(
      "index.php?c=rest/public/v1/document_download",
      data
    );

    dispatch({
      type: FETCH_DOCUMENTATION,
      payload: response.data.data
    });
  } catch (error) {
    dispatch({
      type: SHOW_FEEDBACK,
      payload: error
    });
  }

  dispatch({
    type: IS_LOADING,
    payload: false
  });
};

export const createPayfastOrder = () => async dispatch => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const data = {
    type: "register",
    billing_total: 180.0,
    first_name: userDetails.first_name,
    surname: userDetails.surname,
    email_address: userDetails.email_address
  };

  const response = await admin.post("/payfast-create-order", data);

  dispatch({
    type: CREATE_PAYFAST_FORM,
    payload: response.data
  });
};

export const verifyDocumentation = data => async dispatch => {
  await accountability.post("index.php?c=rest/public/v1/document_agree", data);

  return;
};

export const fetch4In1Token = (formValues, next) => async dispatch => {
  dispatch({
    type: IS_LOADING,
    payload: true
  });

  try {
    const response = await accountability.post(
      "index.php?c=rest/public/v1/public_4in1_request",
      formValues
    );

    localStorage.setItem("user4iN1Tokens", JSON.stringify(formValues));

    dispatch({
      type: FETCH_4_IN_1_TOKEN,
      payload: response.data.data
    });

    next();
  } catch (error) {
    dispatch({
      type: SHOW_FEEDBACK,
      payload: error
    });
  }

  dispatch({
    type: IS_LOADING,
    payload: false
  });
};

export const fetch4in1QuestionsRetry = (token, page) => async dispatch => {
  try {
    const response = await accountability.post(
      "index.php?c=rest/public/v1/public_4in1_verification_get_questions",
      { public_4in1_token: token }
    );

    console.log(response);
    dispatch({
      type: FETCH_4_IN_1_QUESTIONS,
      payload: response.data.data
    });
    page.forceUpdate();

  } catch (error) {
    dispatch({
      type: SHOW_FEEDBACK,
      payload: error
    });
  }
};

export const fetch4in1Questions= token => async dispatch => {
  try {
    const response = await accountability.post(
      "index.php?c=rest/public/v1/public_4in1_verification_get_questions",
      { public_4in1_token: token }
    );

    dispatch({
      type: FETCH_4_IN_1_QUESTIONS,
      payload: response.data.data
    });
  } catch (error) {
    dispatch({
      type: SHOW_FEEDBACK,
      payload: error
    });
  }
};

export const submit4in1Questions = (formValues, page) => async dispatch => {
  try {

    const response = await accountability.post(
      "index.php?c=rest/public/v1/public_4in1_verification_submit_answers",
      formValues
    );
    console.log(response);
    if (response.data.code == 200){
        page.props.nextPage();
    }else{
        page.errorMessage = response.data.message;
        page.forceUpdate();
    }
  } catch (error) {
      console.log(error);
    dispatch({
      type: SHOW_FEEDBACK,
      payload: error
    });
  }
};

export const removeQuestions = () => async dispatch => {
  dispatch({
    type: REMOVE_QUESTIONS
  });
};

export const create4in1Payment = token => async dispatch => {
  console.log("Public 4in1 Token");
  console.log(token);

  try {
    const data = {
      public_4in1_token: token
    };

    const response = await accountability.post(
      "index.php?c=rest/public/v1/public_4in1_payment_create",
      data
    );

    dispatch({
      type: CREATE_4_IN_1_PAYMENT,
      payload: response.data.data
    });

    console.log("Create 4in1 Payment: Should recieve payment token", response);
  } catch (error) {
    dispatch({
      type: SHOW_FEEDBACK,
      payload: error
    });
  }
};

export const fetchReportToken = (type, formValues, next) => async dispatch => {
  dispatch({
    type: IS_LOADING,
    payload: true
  });

  try {

    let apiEndpoint, localStorageKey, successActionType;

    switch (type) {
      case '3in1':
        apiEndpoint = "index.php?c=rest/public/v1/public_3in1_request";
        localStorageKey = "user3iN1Tokens";
        successActionType = FETCH_3_IN_1_TOKEN;
        break;
        // Add more cases as needed
      default:
        throw new Error("Invalid type");
    }

    const response = await accountability.post(apiEndpoint, formValues);

    localStorage.setItem(localStorageKey, JSON.stringify(formValues));

    dispatch({
      type: successActionType,
      payload: response.data.data
    });

    next();
  } catch (error) {
    dispatch({
      type: SHOW_FEEDBACK,
      payload: error
    });
  }

  dispatch({
    type: IS_LOADING,
    payload: false
  });
};

export const fetchResults = token => async dispatch => {
  try {
    const response = await accountability.post(
        "index.php?c=rest/public/v1/public_3in1_search",
        { public_3in1_token: token }
    );
    dispatch({
      type: FETCH_3_IN_1_RESULTS,
      payload: response.data.data
    });
  } catch (error) {
    dispatch({
      type: SHOW_FEEDBACK,
      payload: error
    });
  }
};

export const submit3in1SearchResult = (formValues, page) => async dispatch => {
  try {

    const response = await accountability.post(
        "index.php?c=rest/public/v1/public_3in1_payment_create",
        formValues
    );
    console.log('response');
    console.log(response);
    console.log('response.data');
    console.log(response.data);

    const combinedPayload = {
      ...response.data,           // All data from the response
      selectedReports: formValues.selectedReports // Include the selected reports
    };

    if (response.data.code === 200){
      dispatch({
        type: CREATE_3_IN_1_PAYMENT,
        payload: combinedPayload
      });
      page.props.nextPage();
    }else{
      page.errorMessage = response.data.message;
      page.forceUpdate();
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_FEEDBACK,
      payload: error
    });
  }
};

export const update3in1Payment = (token, selectedResult) => async dispatch => {
  console.log("Public 3in1 Token");
  console.log(token);

  try {
    const data = {
      public_3in1_token: token,
      public_3in1_payfast_response: "Success",
    };

    const response = await accountability.post(
      "index.php?c=rest/public/v1/public_3in1_payment_update",
      data
    );

    const combinedPayload = {
      ...response.data,           // All data from the response
      selectedReports: selectedResult // Include the selected reports
    };

    dispatch({
      type: UPDATE_3_IN_1_PAYMENT,
      payload: combinedPayload
    });

    console.log("Create 3in1 Payment: Should recieve payment token", response);
  } catch (error) {
    dispatch({
      type: SHOW_FEEDBACK,
      payload: error
    });
  }
};

export const generateReport = (type, token) => async dispatch => {
  dispatch({
    type: IS_LOADING,
    payload: true
  });

  try {
    if (type === "fourInOneReport") {
      const data = {
        public_4in1_token: token
      };
      const response = await accountability.post(
          "index.php?c=rest/public/v1/public_4in1_generate_report",
          data
      );
      dispatch({
        type: GENERATE_REPORT,
        payload: response.data.data
      });
    }

    if (type === "threeInOneReport") {

      const selectedReports = JSON.parse(localStorage.getItem("user3iN1ReportResult"));

      const data = {
        public_3in1_token: token,
        tu_result: selectedReports.tu,
        inoxico_result: selectedReports.inoxico,
        xds_result: selectedReports.xds
      };

      console.log('data');
      console.log(data);

      const response = await accountability.post(
          "index.php?c=rest/public/v1/public_3in1_generate_report",
          data
      );

      console.log('response');
      console.log(response);

      dispatch({
        type: GENERATE_REPORT,
        payload: response.data.data
      });
    }

    // console.log(response);

  } catch (error) {
    dispatch({
      type: SHOW_FEEDBACK,
      payload: error
    });
  }

  dispatch({
    type: IS_LOADING,
    payload: false
  });
};


export const cancelPayment = (type, tokens) => async dispatch => {
  let data = {};
  let endpoint = "";

  console.log(type, tokens);

  if (type === "4in1_report") {
    endpoint = "index.php?c=rest/public/v1/public_4in1_payment_update";
    data = {
      ...tokens,
      public_4in1_payfast_response: "Cancelled"
    };
  }

  if (type === "3in1_report") {
    endpoint = "index.php?c=rest/public/v1/public_3in1_payment_update";
    data = {
      ...tokens,
      public_3in1_payfast_response: "Cancelled"
    };
  }

  if (type === "register") {
    endpoint = "index.php?c=rest/public/v1/register_payment_update";
    data = {
      ...tokens,
      registration_payfast_response: "Cancelled"
    };
  }

  console.log(data);

  try {
    const response = await accountability.post(endpoint, data);

    console.log(response);
  } catch (error) {
    dispatch({
      type: SHOW_FEEDBACK,
      payload: error
    });
  }
};

export const saveReferral = formValues => async dispatch => {
  console.log(formValues);

  const response = await accountability.post(
    `/index.php?c=rest/public/v1/send_referral_details_email`,
    formValues
  );

  if (response.data.message === "Success") {
    // history.push("/thank-you");
    window.location.href = "/thank-you"
  }
};

export const sendMail = formValues => async dispatch => {
  const response = await admin.post(`/general-enquiry`, formValues);
  if (response.data === "success") {
    // history.push("/thank-you");
    window.location.href = "/thank-you"
  }
};

export const verifyAge = response => async dispatch => {
  dispatch({
    type: VERIFY_AGE,
    payload: response
  });
};

export const verifyPaymentType = (paymentToken, paymentType) => async dispatch => {

  try {
    const data = {
      registration_payment_token: paymentToken,
      registration_payment_type: paymentType
    };

    const response = await accountability.post(
      "index.php?c=rest/public/v1/register_payment_type",
      data
    );

    dispatch({
      type: VERIFY_PAYMENT_TYPE,
      payload: paymentType
    });
    
    } catch (error) {
      dispatch({
        type: VERIFY_PAYMENT_TYPE,
        payload: error
      });
  }
}