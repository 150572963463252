import React from "react";
import { Route } from "react-router-dom";
import Footer from "../components/ui/footer/Footer";
import FooterWithForm from "../components/ui/footer/FooterWithForm";
import FooterStripped from "../components/ui/footer/FooterStripped";
import FooterStrippedNoMargin from "../components/ui/footer/FooterStrippedNoMargin";

function FooterRoutes() {
  return (
    <React.Fragment>
      <Route path="/" exact component={FooterWithForm} />
      <Route path="/services" exact component={FooterWithForm} />
      <Route path="/services/:slug" exact component={FooterStripped} />
      <Route path="/services/:slug/:secondary" exact component={FooterStripped} />
      <Route path="/register" exact component={FooterStripped} />
      <Route
        path="/services/comprehensive-4-in-1-consumer-credit-report/register"
        exact
        component={FooterStripped}
      />
      <Route
        path="/services/comprehensive-3-in-1-commercial-credit-report/register"
        exact
        component={FooterStripped}
      />
      <Route path="/login" exact component={Footer} />
      <Route path="/about-us" exact component={FooterWithForm} />
      <Route path="/news" exact component={FooterWithForm} />
      <Route path="/news/:slug" exact component={FooterStripped} />
      <Route path="/thank-you" exact component={FooterStrippedNoMargin} />
      <Route path="/social-feed" exact component={FooterWithForm} />
      <Route path="/contact-us" exact component={Footer} />
      <Route path="/referral" exact component={Footer} />
      <Route path="/terms-and-conditions" exact component={FooterStripped} />
      <Route path="/privacy-policy" exact component={FooterStripped} />
      <Route path="/cookie-policy" exact component={FooterStripped} />
      <Route path="/disclaimer" exact component={FooterStripped} />
      <Route
        path="/register-complete"
        exact
        component={FooterStrippedNoMargin}
      />
      <Route
        path="/payment-complete"
        exact
        component={FooterStrippedNoMargin}
      />
      <Route path="/register-cancel" exact component={FooterStrippedNoMargin} />
      <Route path="/reset-password" exact component={FooterStripped} />
      <Route
        path="/reset-password-complete"
        exact
        component={FooterStrippedNoMargin}
      />
    </React.Fragment>
  );
}

export default FooterRoutes;
