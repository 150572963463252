import "./Form.scss";
import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { renderInput, renderSelect, renderTextArea, renderCheckbox, renderSelect2, renderRadioButton } from "./renderField";
import { connect } from "react-redux";
import { sendMail } from "../../actions/index";
import FormContainer from "../ui/container/form-container/FormContainer";
import Button from "../ui/button/Button";
// import Recaptcha from "react-recaptcha";
import { allLetter } from "../../utils/functions/index";
import ReCAPTCHA from "react-google-recaptcha";
import scrollToFirstError from './SmoothScrollToErrorFields.js';

class FormContact extends Component {
  state = {
    isMember: false,
    recapcha: {
      active: false,
      isVisible: false
    },
    submit: false
  };

  componentDidMount(){
    // this.props.fetchServicesList()
  }

  onSubmit = formValues => {
     
    if (!this.state.recapcha.active) {
      this.setState({
        recapcha: {
          isVisible: true
        }
      });
    } else {
      this.props.sendMail(formValues);
      this.setState({submit: true})
    }
  };

  handleMemberOptions = e => {
    if (e.target.value === "An Accountability Member".toLowerCase()) {
      this.setState({ isMember: true });
    } else {
      this.setState({ isMember: false });
    }
  };

  handleDepartmentChange = e => {
    this.setState({ department: e.target.value });
  }

  renderRecapchaError() {
    if (this.state.recapcha.isVisible) {
      return (
        <div>
          <div className="error-message">The recapcha field is required</div>
        </div>
      );
    }
  }

  recapChange = () => {
    this.setState({
      recapcha: {
        active: true
      }
    });
  };

  renderRecaptchaField = ({ field, input, label, meta, placeholder }) => {
    return (
      <div>
        <div>
          <ReCAPTCHA
            sitekey="6LfL17kUAAAAAOLB-BxODBlF9tIQCpESslxA_lLj"
            onChange={this.recapChange}
          />
        </div>
        <div>{this.renderRecapchaError()}</div>
        <br />
      </div>
    );
  };

  renderHelpOptions = (dep) => {

    let services = Object.values(this.props.services)

    // services = [...services, {
    //   preview_title: '<div>My Personal Credit Check</div>',
    //   department: 'group'
    // }]

    services.sort(function(a, b){
      if(a.preview_title < b.preview_title) { return -1; }
      if(a.preview_title > b.preview_title) { return 1; }
      return 0;
    })

    return (
      <>
        {services.map(({preview_title, department}) => {
          if(department === dep){
            return <option dangerouslySetInnerHTML={{__html: preview_title}} />
          }          
        })}
      </>
    ) 
  }

  render() {
    
    const memberOptions = [
      "Not an Accountability Member",
      "An Accountability Member"
    ];

    return (
      <FormContainer noPadding>
        <form
          onSubmit={this.props.handleSubmit(this.onSubmit)}
          className="ui form error"
        >
           {'full_name' && <div name={`position-full_name`} />}
          <Field
            name="full_name"
            component={renderInput}
            label="Name & Surname:"
          />
           {'phone' && <div name={`position-phone`} />}
          <Field
            name="phone"
            type="number"
            component={renderInput}
            label="Contact Number:"
          />
           {'email' && <div name={`position-email`} />}
          <Field
            name="email"
            type="email"
            component={renderInput}
            label="Email Address:"
          />
           {'member_status' && <div name={`position-member_status`} />}
          <Field
            name="member_status"
            component={renderSelect}
            label="I am currently"
            options={memberOptions}
            onChange={this.handleMemberOptions}
          />
          {'help_options' && <div name={`position-help_options`} />}
          {this.state.isMember && <Field
            name="help_options"
            component={renderSelect2}
            label="I need help with"
          >
            <option value="">Please select</option>
            <option>Collections</option>
            <option>Credit Checks</option>
            <option>Web training</option>
            <option>Other</option>
          </Field>}

          {!this.state.isMember && (
            <>
              <label>I need help with:</label><br /><br />
              <div className="_field-group">
                <Field
                  name="help_options_group"
                  component={renderSelect2}
                  label={<span style={{textAlign: 'center', display: 'block'}}>Accountability Group (Pty) Ltd <br /><br />(Credit Checks)</span>}
                >
                  <option value="">Please select an option</option>
                  {this.renderHelpOptions('group')}
                </Field>
                <Field
                  name="help_options_solutions"
                  component={renderSelect2}
                  label={<span style={{textAlign: 'center', display: 'block'}}>Accountability Solutions (Pty) Ltd <br /><br />(Debt Collection)</span>}
                >
                  <option value="">Please select an option</option>
                  {this.renderHelpOptions('solutions')}
                </Field>
              </div>
            </>
          )}

          <div style={{display: 'flex', columnGap: '20px'}}>
            <label style={{width: '100px'}}>
              <Field name="for_who" component="input" type="radio" value="For Me" />
              {' '}
              For Me
            </label>
            <label>
              <Field name="for_who" component="input" type="radio" value="For My Business" />
              {' '}
              For My Business
            </label>
          </div>

          <br />

           {'message' && <div name={`position-message`} />}
          <Field name="message" component={renderTextArea} label="Message:" />

          {'agree_contact' && <div name={`position-agree_contact`} />}
          <Field
            name="agree_contact"
            type="checkbox"
            component={renderCheckbox}
            label="I hereby provide consent to be contacted by an Accountability representative."
            noMargin
          />

          <br />
          <br />

          <div className="form-footer">
            <div className="form-footer-item">
              <Field name="recaptcha" component={this.renderRecaptchaField} />
            </div>

            <div className="form-footer-item">
              { !this.state.submit &&
                (
                  <Button
                    type="submit"
                    theme="green"
                    text="SUBMIT"
                    classNames={["fat"]}
                  />
                )
              }
              { this.state.submit &&
                (
                  <Button
                    type="button"
                    theme="green"
                    text="SUBMITTING"
                    classNames={["fat"]}
                  />
                )
              }
            </div>
          </div>
        </form>
      </FormContainer>
    );
  }
}

const validate = formValues => {
  const errors = {};

  if (!formValues.agree_contact) {
    errors.agree_contact = "* Required";
  }

  if (!formValues.full_name) {
    // on run if the user did not enter a title
    errors.full_name = "* You must enter a Name & Surname";
  }

  if (formValues.full_name) {
    let s = allLetter(formValues.full_name);
    if (!s) {
      errors.full_name = "* Please include only letters.";
    }
  }

  if (!formValues.phone) {
    // on run if the user did not enter a title
    errors.phone = "* You must enter a contact number";
  } else if (formValues.phone.length !== 10) {
    errors.phone = "* Please enter a valid phone number";
  }

  if (!formValues.email) {
    // on run if the user did not enter a title
    errors.email = "* You must enter an email";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(formValues.email)
  ) {
    errors.email = "* Invalid email address";
  }

  if(!formValues.department){
    errors.department = "* Please select department"
  }

  if(!formValues.member_status){
    errors.member_status = "* Please select member status"
  }

  if (!formValues.message) {
    // on run if the user did not enter a title
    errors.message = "* You must enter a Message";
  }

  return errors;
};

const formWrapped = reduxForm({
  form: "formContact",
  validate: validate,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(FormContact);

const mapStateToProps = state => {
  return {
    services: state.services
  }
}

export default connect(mapStateToProps, { sendMail })(formWrapped);
