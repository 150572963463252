import React from "react";
import { Route, Switch } from "react-router-dom";
import SocialFeed from "../components/pages/social-feed/SocialFeed";
import FourInOneReport from "../components/pages/4-in-1-report/FourInOneReport";
import ThreeInOneReport from "../components/pages/3-in-1-report/ThreeInOneReport";
import Success from "../components/forms/Success";
import RegisterSuccess from "../components/forms/RegisterSuccess";
import PaymentSuccess from "../components/forms/PaymentSuccess";
import RegisterCancel from "../components/forms/RegisterCancel";
import RegisterNotify from "../components/forms/payfast/Notify";
import TermsAndConditions from "../components/pages/terms-and-conditions/TermsAndConditions";
import CookiePolicy from "../components/pages/cookie-policy/CookiePolicy";
import PrivacyPolicy from "../components/pages/privacy-policy/PrivacyPolicy";

import Disclaimer from "../components/pages/disclaimer/Disclaimer";
import PaymentCancel from "../components/forms/PaymentCancel";
import Home from "../components/pages/home/Home";
import AboutUs from "../components/pages/about-us/AboutUs";
import Services from "../components/pages/services/Services";
import Service from "../components/pages/services/Service";
import News from "../components/pages/news/News";
import NewsDetail from "../components/pages/news/NewsDetail";
import ContactUs from "../components/pages/contact/ContactUs";
import Register from "../components/pages/register/Register";
import Referal from "../components/pages/referal/Referal";
import Login from "../components/pages/login/Login";
import ForgotPassword from "../components/forms/ForgotPassword";
import ForgotPasswordSuccess from "../components/forms/ForgotPasswordSuccess";
import PageNotFound from '../components/pages/404/PageNotFound'
import PageNotFound404 from '../components/pages/404/PageNotFound404'
import ServicesCategories from "../components/pages/services/ServicesCategories";

function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/services" exact component={Services} />
      <Route path="/services/:slug" exact component={ServicesCategories} />
      <Route path="/services/:slug/:secondary" exact component={Service} />
      <Route path="/about-us" exact component={AboutUs} />
      <Route path="/news" exact component={News} />
      <Route path="/news/:slug" exact component={NewsDetail} />
      <Route path="/contact-us" exact component={ContactUs} />
      <Route path="/referral" exact component={Referal} />
      <Route path="/thank-you" exact component={Success} />
      <Route path="/register-complete" exact component={RegisterSuccess} />
      <Route path="/payment-complete" exact component={PaymentSuccess} />
      <Route path="/register-cancel" exact component={RegisterCancel} />
      <Route path="/reset-password" exact component={ForgotPassword} />

      <Route
        path="/reset-password-complete"
        exact
        component={ForgotPasswordSuccess}
      />
      <Route path="/payment-cancel" exact component={PaymentCancel} />
      <Route path="/notify" exact component={RegisterNotify} />
      <Route path="/social-feed" exact component={SocialFeed} />
      <Route path="/register" exact component={Register} />
      <Route
        path="/services/comprehensive-4-in-1-consumer-credit-report/register"
        exact
        component={FourInOneReport}
      />
      <Route
        path="/services/comprehensive-3-in-1-commercial-credit-report/register"
        exact
        component={ThreeInOneReport}
      />
      <Route path="/login" exact component={Login} />
      <Route
        path="/terms-and-conditions"
        exact
        component={TermsAndConditions}
      />
      <Route
        path="/cookie-policy"
        exact
        component={CookiePolicy}
      />
      {/*<Route
        path="/privacy-policy"
        exact
        component={PrivacyPolicy}
      />*/}
      
      <Route path="/disclaimer" exact component={Disclaimer} />
      
      <Route
        path="/page-not-found"
        exact
        component={PageNotFound404}
      />
      <Route
        component={PageNotFound}
      />
    </Switch>
  );
}

export default Routes;
