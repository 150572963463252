import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { register, fetchMemberDetails, fetchMemberReference, isLoading } from "../../../actions";
import SignatureCanvas from "react-signature-canvas";
import Button from "../../ui/button/Button";
import ButtonList from "../../ui/button/ButtonList";
import Section from "../../ui/section/Section";
import SectionHeader from "../../ui/section/SectionHeader";
import Container from "../../ui/container/Container";
import validate from "./validate";
import Grid from "../../ui/grid/Grid";
import GridItem from "../../ui/grid/GridItem";
import Loader from "../../ui/loader/Loader";
import { removeParam, getQueryString } from "../../../utils/functions/index";
import scrollToFirstError from '../SmoothScrollToErrorFields.js';
import resolution from '../../../assets/docs/Company-Resolution.docx'

import {
  renderInput,
  renderHiddenInput,
  FieldFileInput,
  renderCheckbox
} from "../renderField";
import Text from "../../ui/text/Text";

class WizardFormThirdPage extends Component {
  constructor(props) {
    super(props);

    this.upload_1 = React.createRef();
    this.upload_2 = React.createRef();
    this.upload = React.createRef();

    this.state = {
      trimmedDataURL: "",
      trimmedDataURL2: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let regToken = getQueryString("registration_token");

    if (regToken) {
      this.props.fetchMemberDetails(regToken);
    }

    const ip = require("ip");
    const today = new Date();
    const date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    const time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

    this.setState({
      ipAddress: ip.address(),
      dateTime: date + " " + time
    });
  }

  async componentDidUpdate() {
    if (this.props.initialValues) {
      if (!this.state.upload1 && !this.state.trimmedDataURL && this.props.initialValues.company_signature_primary_data !== '') {
        await this.setState({
          upload1: this.props.initialValues.company_signature_primary_data
        });      

        if (
          this.state.upload1 ===
          this.props.initialValues.company_signature_primary_data
        ) {
          this.props.change(
            "company_signature_primary_data",
            this.props.initialValues.company_signature_primary_data
          );

          var image = new Image();
          image.src = this.props.initialValues.company_signature_primary_data;
          this.upload_1.current.src = this.props.initialValues.company_signature_primary_data;
        }
      }

      if (this.props.initialValues.company_signature_secondary_data) {
        if (!this.state.upload2 && !this.state.trimmedDataURL2 && this.props.initialValues.company_signature_secondary_data !== '') {

          await this.setState({
            upload2: this.props.initialValues.company_signature_secondary_data
          });         

          if (
            this.state.upload2 ===
            this.props.initialValues.company_signature_secondary_data
          ) {
            this.props.change(
              "company_signature_secondary_data",
              this.props.initialValues.company_signature_secondary_data
            );

            var image = new Image();
            image.src = this.props.initialValues.company_signature_secondary_data;
            this.upload_2.current.src = this.props.initialValues.company_signature_secondary_data;
          }
        }
      }
    }
  }

  sigPad1 = {};
  sigPad2 = {};

  clear1 = () => {
    this.sigPad1.clear();
    this.onEnd(1);
    this.setState({primarySignature: ''});
    console.log(this.props.initialValues)
  };

  clear2 = () => {
    this.sigPad2.clear();
    this.onEnd(2);
    this.setState({secondarySignature: ''});
  };

  trim = () => {
    this.setState({
      trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL("image/png")
    });
  };

  onEnd = number => {
    if (number === 1) {
      if (this.upload_1.current) {
        this.upload_1.current.src = null;
      }

      let signature = this.sigPad1.getTrimmedCanvas().toDataURL("image/png");

      this.setState({
        trimmedDataURL: signature,
        upload1: null,
        primarySignature: signature
      });

      this.props.change(
        "company_signature_primary_data",
        signature
      );

      this.props.uploadSignature(
        signature
      );
    }

    if (number === 2) {
      if (this.upload_2.current) {
        this.upload_2.current.src = null;
      }

      let signature = this.sigPad2.getTrimmedCanvas().toDataURL("image/png");

      this.setState({
        trimmedDataURL2: signature,
        upload2: null,
        secondarySignature: signature
      });

      this.props.change(
        "company_signature_secondary_data",
        signature
      );

      this.props.uploadSignature(
        signature
      );
    }
  };

  onSignatureSign = data => {
    console.log(data);
  };

  async handleImageChange(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        this.setState({ upload: reader.result });
        resolve(resolve);
      };
    });
  }
  
  async handleImageChange1(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        this.setState({ primarySignature: reader.result });
        resolve(resolve);
      };
    });
  }

  async handleImageChange2(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        this.setState({ secondarySignature: reader.result });
        resolve(resolve);
      };
    });
  }

  onSubmit = async formValues => {

    this.props.isLoading(true);

    formValues.company_signature_primary_date = this.state.dateTime;
    formValues.company_signature_primary_ip = this.state.ipAddress;
    formValues.company_signature_secondary_date = this.state.dateTime;
    formValues.company_signature_secondary_ip = this.state.ipAddress;

    if (getQueryString("registration_token")){
      formValues.registration_token = getQueryString("registration_token");
    } 

    if (!formValues.company_postal_address_line_1) {
      formValues.company_postal_address_line_1 =
        formValues.company_physical_address_line_1;
    }

    if (!formValues.company_postal_address_line_2) {
      formValues.company_postal_address_line_2 =
        formValues.company_physical_address_line_2;
    }

    if (!formValues.company_postal_address_line_3) {
      formValues.company_postal_address_line_3 =
        formValues.company_physical_address_line_3;
    }

    let signature1 = formValues.company_signature_primary_data;

    if(this.state.primarySignature === ''){
      formValues.company_signature_primary_data = '';
    } else {
      if (signature1) {
        if (signature1.type !== undefined) {
          if (signature1.type === "image/jpeg" || "image/png") {
            await this.handleImageChange1(signature1);
            console.log(
              "this.state.primarySignature",
              this.state.primarySignature
            );
            formValues.company_signature_primary_data = this.state.primarySignature;
          }
        }
      }
    }      

    let signature2 = formValues.company_signature_secondary_data;
    if(this.state.secondarySignature === ''){
      formValues.company_signature_secondary_data = '';
    } else {
      if (signature2) {
        if (signature2.type !== undefined) {
          if (signature2.type === "image/jpeg" || "image/png") {
            await this.handleImageChange2(signature2);
            console.log(
              "this.state.secondarySignature",
              this.state.secondarySignature
            );
            formValues.company_signature_secondary_data = this.state.secondarySignature;
          }
        }
      }
    }

    let upload = formValues.company_resolution_form

    if(upload){
      if (upload.type !== undefined && upload.type === "application/pdf") {      
        await this.handleImageChange(upload);
        console.log(
          "this.state.upload",
          this.state.upload
        );
        formValues.company_resolution_form = this.state.upload;
      }
    }

    await this.props.register(formValues);

    localStorage.setItem(
      "userDetails",
      JSON.stringify({
        first_name: formValues.company_primary_user_name,
        surname: formValues.company_name,
        email_address: formValues.company_signatory_email
      })
    );

    this.props.nextPage();
    this.props.isLoading(false);
  };

  //displays the upload below the input field and resets the drawing pad
  handleImageUpload = async data => {
    await this.setState({ upload: data });
    this.props.change("company_resolution_form", data);
    // this.upload.current.src = URL.createObjectURL(data);
  };

  //displays the upload below the input field and resets the drawing pad
  handleImageUpload1 = async data => {
    await this.setState({ upload1: data });
    this.props.change("company_signature_primary_data", data);
    this.upload_1.current.src = URL.createObjectURL(data);
    this.sigPad1.clear();
  };

  //displays the upload below the input field and resets the drawing pad
  handleImageUpload2 = async data => {
    await this.setState({ upload2: data });
    this.props.change("company_signature_secondary_data", data);
    this.upload_2.current.src = URL.createObjectURL(data);
    this.sigPad2.clear();  
  };

  render() {
    const { handleSubmit, previousPage } = this.props;

    let canvasWidth = 660;
    if (window.innerWidth < 992) {
      canvasWidth = window.innerWidth - 52;
    }

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <Section>
          <SectionHeader autoWidth noPadding title={window.innerWidth < 992 ? `User Details` : `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;User Details`} />
          <Container>
            <Grid>
              <GridItem classNames={["col-md-5"]}>
                <label htmlFor="">Authorised Signatory 01:</label>
                <Text
                  type="p"
                  text="The Authorised Signatory refers to the representative assigned with the authority to commit the organization to a binding agreement."
                />
                {'company_signatory_name' && <div name={`position-company_signatory_name`} />}
                <Field
                  name="company_signatory_name"
                  type="text"
                  component={renderInput}
                  label="Name & Surname"
                />
                {'company_signatory_position' && <div name={`position-company_signatory_position`} />}
                <Field
                  name="company_signatory_position"
                  type="text"
                  component={renderInput}
                  label="Position"
                />
                {'company_signatory_email' && <div name={`position-company_signatory_email`} />}
                <Field
                  name="company_signatory_email"
                  type="text"
                  component={renderInput}
                  label="Email Address"
                />
              </GridItem>

              <GridItem classNames={["col-md-7"]}>

                <p>Should you be submitting this registration on behalf of the owner/directors, please complete and attach the Company Resolution Form.</p>

                <ButtonList>
                  <a href={resolution} className="upload-btn button" style={{padding: '10px 37px'}}>DOWNLOAD RESOLUTION FORM</a>

                  <Field
                    btnClass="company-resolution-form"
                    onChange={this.handleImageUpload}
                    name="company_resolution_form"
                    inputName="company_resolution_form"
                    type="file"
                    component={FieldFileInput}
                    label="UPLOAD RESOLUTION FORM"
                  />
                  
                </ButtonList>

                {this.state.upload && (
                    <>
                      <br />
                      <p>Document uploaded &#10003;</p>
                    </>
                  )}

                <br /><br />

                <label htmlFor="">Authorised Signatory 01:</label>
                <SignatureCanvas
                  onEnd={() => this.onEnd(1)}
                  penColor="#000"
                  ref={ref => {
                    this.sigPad1 = ref;
                  }}
                  canvasProps={{
                    width: canvasWidth,
                    height: 200,
                    className: "sigCanvas"
                  }}
                />
                {'company_signature_primary_data' && <div name={`position-company_signature_primary_data`} />}
                <Field
                  name="company_signature_primary_data"
                  type="hidden"
                  val={this.state.trimmedDataURL}
                  component={renderHiddenInput}
                />
                <div>Max file size: 1 MB</div>
                {/* <button onClick={this.trim}>Trim</button> */}
                <ButtonList>
                  {'company_signature_primary_data' && <div name={`position-company_signature_primary_data`} />}
                  <Field
                    btnClass="button"
                    accept=".jpg, .png, .jpeg"
                    onChange={this.handleImageUpload1}
                    name="company_signature_primary_data"
                    inputName="company_signature_primary_data"
                    type="file"
                    component={FieldFileInput}
                    label={<>OR UPLOAD SIGNATURE<br />(JPEG only)</>}
                  />


                  <Button
                    text="CLEAR SIGNATURE CANVAS"
                    theme="green-ghost"
                    onClick={this.clear1}
                  />
                </ButtonList>

                {this.state.upload1 && (
                  <img
                    ref={this.upload_1}
                    style={{
                      border: "3px solid #ccc",
                      marginTop: "5px",
                      maxWidth: "120px"
                    }}
                    src=""
                    id="upload_1"
                    alt=""
                  />
                )}
              </GridItem>
            </Grid>
            <br />
            <br />
            <Grid>
              <GridItem classNames={["col-md-5"]}>
                <label htmlFor="">Authorised Signatory 02: (Optional)</label>
                <Text
                  type="p"
                  text="The Authorised Signatory refers to the representative assigned with the authority to commit the organization to a binding agreement."
                />
                {'company_signatory_secondary_name' && <div name={`position-company_signatory_secondary_name`} />}
                <Field
                  name="company_signatory_secondary_name"
                  type="text"
                  component={renderInput}
                  label="Name & Surname"
                />
                {'company_signatory_secondary_position' && <div name={`position-company_signatory_secondary_position`} />}
                <Field
                  name="company_signatory_secondary_position"
                  type="text"
                  component={renderInput}
                  label="Position"
                />
                {'company_signatory_secondary_email' && <div name={`position-company_signatory_secondary_email`} />}
                <Field
                  name="company_signatory_secondary_email"
                  type="text"
                  component={renderInput}
                  label="Email Address"
                />
              </GridItem>

              <GridItem classNames={["col-md-7"]}>
                <label htmlFor="">Authorised Signatory 02: (Optional)</label>
                <SignatureCanvas
                  onEnd={() => this.onEnd(2)}
                  penColor="#000"
                  ref={ref => {
                    this.sigPad2 = ref;
                  }}
                  canvasProps={{
                    width: canvasWidth,
                    height: 200,
                    className: "sigCanvas"
                  }}
                />
                <div>Max file size: 1 MB</div>

                {/* <Field
                  name="company_signature_secondary_data"
                  type="hidden"
                  val={this.state.trimmedDataURL}
                  component={renderHiddenInput}
                /> */}

                {/* <button onClick={this.trim}>Trim</button> */}

                <ButtonList>
                  {'company_signature_secondary_data' && <div name={`position-company_signature_secondary_data`} />}
                  <Field
                    btnClass="button"
                    accept=".jpg, .png, .jpeg"
                    onChange={this.handleImageUpload2}
                    name="company_signature_secondary_data"
                    inputName="company_signature_secondary_data"
                    type="file"
                    component={FieldFileInput}
                    label={<>OR UPLOAD SIGNATURE<br />(JPEG only)</>}
                  />

                  <Button
                    text="CLEAR SIGNATURE CANVAS"
                    theme="green-ghost"
                    onClick={this.clear2}
                  />
                </ButtonList>

                {this.state.upload2 && (
                  <img
                    ref={this.upload_2}
                    style={{
                      border: "3px solid #ccc",
                      marginTop: "5px",
                      maxWidth: "120px"
                    }}
                    src=""
                    id="upload_2"
                    alt=""
                  />
                )}
              </GridItem>
            </Grid>
            <br />
            <br />
            <Grid>
              <GridItem classNames={["col-md-5"]}>
                <label htmlFor="">Primary User</label>
                <Text
                  type="p"
                  text="The Primary User refers to your company liaison and/or employee who will be working with the system."
                />
                {'company_primary_user_name' && <div name={`position-company_primary_user_name`} />}
                <Field
                  name="company_primary_user_name"
                  type="text"
                  component={renderInput}
                  label="Name & Surname"
                />
                {'company_primary_position' && <div name={`position-company_primary_position`} />}
                <Field
                  name="company_primary_position"
                  type="text"
                  component={renderInput}
                  label="Position"
                />
                {'company_primary_email' && <div name={`position-company_primary_email`} />}
                <Field
                  name="company_primary_email"
                  type="text"
                  component={renderInput}
                  label="Email Address"
                />
              </GridItem>

              <GridItem classNames={["col-md-7"]}>
                {'agree_signtature_auth' && <div name={`position-agree_signtature_auth`} />}
                <Field
                  name="agree_signtature_auth"
                  type="checkbox"
                  component={renderCheckbox}
                  label="By affixing my signature hereto, I agree that I am the authorised signatory for this entity and that the signature affixed hereto is a legal representation thereof."
                  noMargin
                />
                {'company_agree_terms' && <div name={`position-company_agree_terms`} />}
                <Field
                  name="company_agree_terms"
                  type="checkbox"
                  component={renderCheckbox}
                  label="I agree to your <a class='text-decoration_underline' href='/terms-and-conditions' target='_blank'>Terms & Conditions</a>."
                  noMargin
                />
                {'company_agree_disclaimer' && <div name={`position-company_agree_disclaimer`} />}
                <Field
                  name="company_agree_disclaimer"
                  type="checkbox"
                  component={renderCheckbox}
                  label="I agree to your <a class='text-decoration_underline' href='/disclaimer' target='_blank'>Disclaimer</a>."
                  noMargin
                />
                <Field
                  name="company_agree_privacy_policy"
                  type="checkbox"
                  component={renderCheckbox}
                  label="I have read and understood your <a class='text-decoration_underline' href='/privacy-policy' target='_blank'>Privacy Policy</a>."
                  noMargin
                />
                <Field
                  name="company_agree_marketing_communication_and_information"
                  type="checkbox"
                  component={renderCheckbox}
                  label="I consent to receive marketing communication and information from Accountability."
                  noMargin
                />
                <Field
                  name="consent_yearly"
                  type="checkbox"
                  component={renderCheckbox}
                  label="I consent to a yearly commercial credit report @ R585.99 (incl. VAT)."
                  noMargin
                />
                <br />
                <label htmlFor="">Extra Options (Stickers) </label>
                {'company_stickers_received' && <div name={`position-company_stickers_received`} />}
                <Field
                  name="company_stickers_received"
                  type="checkbox"
                  component={renderCheckbox}
                  label="Received (R 469.62 Incl. VAT)"
                  value="Received (R 469.62 Incl. VAT)"
                  noMargin
                />
                {'company_stickers_posted' && <div name={`position-company_stickers_posted`} />}
                <Field
                  name="company_stickers_posted"
                  type="checkbox"
                  component={renderCheckbox}
                  label="To be posted (R 469.62 excl. postage)"
                  value="To be posted (R 469.62 excl. postage)"
                  noMargin
                />
                {'company_stickers_electronic' && <div name={`position-company_stickers_electronic`} />}
                <Field
                  name="company_stickers_electronic"
                  type="checkbox"
                  component={renderCheckbox}
                  label="Electronic Sticker (R 98.86 p.month)"
                  value="Electronic Sticker (R 98.862 p.month)"
                  noMargin
                />
                {'company_stickers_not_required' && <div name={`position-company_stickers_not_required`} />}
                <Field
                  name="company_stickers_not_required"
                  type="checkbox"
                  component={renderCheckbox}
                  label="Not Required"
                  value="Not Required"
                  noMargin
                />
                <br />
                {this.props.loading ? (
                <Loader />
              ) : (
                <ButtonList>
                  <Button
                    type="button"
                    onClick={previousPage}
                    classNames={["previous", "centered"]}
                    theme="green"
                    text="PREVIOUS"
                  >
                    PREVIOUS
                  </Button>

                  <Button
                    type="submit"
                    classNames={["next", "centered"]}
                    theme="green"
                    text="NEXT"
                  >
                    Next
                  </Button>
                </ButtonList>
              )}
              </GridItem>
            </Grid>
          </Container>
        </Section>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    initialValues: state.register.memberDetails,
    loading: state.isLoading
  };
};

const formWrapped = reduxForm({
  form: "wizard", //Form name is same
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(WizardFormThirdPage);

export default connect(mapStateToProps, { register, fetchMemberDetails, isLoading })(
  formWrapped
);