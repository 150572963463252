import "./TermsAndConditions.scss";
import React from "react";
import BannerHeader from "../../ui/banner/BannerHeader";
import Section from "../../ui/section/Section";
import Container from "../../ui/container/Container";
import Grid from "../../ui/grid/Grid";
import GridItem from "../../ui/grid/GridItem";
import ButtonList from "../../ui/button/ButtonList";
import Button from "../../ui/button/Button";
import { Helmet } from "react-helmet";
import {Link} from 'react-router-dom'

function TermsAndConditions() {
  return (
    <div className="terms-and-conditions">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms & Conditions | Accountability Group Pty Ltd</title>
        <meta
          name="description"
          content="By clicking on the 'Submit' option or by using this Website, you agree to be bound by these Terms and Conditions of Use, as amended from time to time. If you do not agree, you may not make use of the Accountability Services."
        />
        <link rel="canonical" href={window.location} />
      </Helmet>

      <BannerHeader
        image="/assets/image/banners-optimized/services-banner@2x-compressor.jpg"
        title="Terms & Conditions"
        
      >
          <p className="white breadcrumb">
            <Link to="/">Home</Link> | <Link to="/terms-and-conditions">Terms & Conditions</Link>
          </p>
        </BannerHeader>
      <Section>
        <Container>
          <Grid>
            <GridItem classNames={["col-md-12"]}>
              <h2 className="fontPurple" style={{ fontSize: "32px" }}>
              Accountability Group (Pty) Ltd and Accountability Solutions (Pty)
              Ltd Subscriber Terms and Conditions of Use.
              </h2>
              <p>
              By clicking the “Submit” option or using this website, you agree to be bound by these Terms and Conditions of Use, as amended from
time to time. If you do not agree, you may not use Accountability’s services.
              </p>
              <h2 className="fontPurple">Definitions</h2>
              <ul className="terms-list">
                <li>
                  <span>1.1</span>
                  <p>
                  For the purposes of these Terms and Conditions of Use, the following terms shall have the meanings assigned to them below,
                  unless the context clearly requires otherwise.
                  </p>
                </li>
                <li>
                  <span>1.1.1</span>
                  <p>
                  <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}><b>“Accountability”</b></strong> or <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}>“Accountability entities”</strong> refers to Accountability Group (Pty) Ltd (registration number
2008/012163/07) and Accountability Solutions (Pty) Ltd (registration number 2017/365254/07), both incorporated under
the laws of South Africa. The primary place of business is 29 Bella Rosa Road, Rosenpark, Bellville, 7530, which is also
elected as the domicilium citandi et executandi for purposes of these Terms and Conditions of Use.
Details regarding the directors of Accountability and additional company information can be found on our website;
                  </p>
                </li>
                <li>
                  <span>1.1.2</span>
                  <p>
                  <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}>“Accountability Services”</strong> refers to the information-related services provided to Subscribers by Accountability via the
website, as updated from time to time. These services include both paid and free offerings, as well as information-related
services sourced from entities other than Credit Bureaus;
                  </p>
                </li>
                <li>
                  <span>1.1.3</span>
                  <p>
                  <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}>“Commencement Date”</strong> refers to the date of your profile activation;
                  </p>
                </li>
                <li>
                  <span>1.1.4</span>
                  <p>
                  <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}>“Confidential Information”</strong> refers to any information that is owned by or known to the parties to this Agreement, which
is not available in the public domain and was created or obtained as a result of this Agreement;
                  </p>
                </li>
                <li>
                  <span>1.1.5</span>
                  <p>
                  <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}>“Consent”</strong> refers to any freely given, specific, and informed expression of will by which natural persons agree to the
processing of personal information relating to them;
                  </p>
                </li>
                <li>
                  <span>1.1.6</span>
                  <p>
                  <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}>“Consumer”</strong> refers to an individual as defined in Section 1 of the NCA. For the purposes of the NCA, registered companies
and/or juristic persons are also deemed to be “Consumers”;
                  </p>
                </li>
                <li>
                  <span>1.1.7</span>
                  <p>
                  <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}>“Consumer Credit Information”</strong> refers to the information described in detail in Section 70 of the NCA;
                  </p>
                </li>
                <li>
                  <span>1.1.8</span>
                  <p>
                  <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}>“Credit Bureau”</strong> means a Credit Bureau as defined in Section 43 of the NCA and registered as such with the National
Credit Regulator of South-Africa;
                  </p>
                </li>
                <li>
                  <span>1.1.9</span>
                  <p>
                  <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}>“ECT Act”</strong> means the Electronic Communications and Transactions Act No. 25 of 2002;
                  </p>
                </li>
                <li>
                  <span>1.1.10</span>
                  <p>
                  <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}>“Force Majeure”</strong> means any act of God, strike, riot, war (whether declared or not) embargo, international restriction,
shortage of transport facilities, any order of a regulatory authority and the downtime of a communications medium
which is not under the control of the party concerned;
                  </p>
                </li>
                <li>
                  <span>1.1.11</span>
                  <p>
                  <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}>“NCA”</strong> means the National Credit Act No. 34 of 2005, as amended;
                  </p>
                </li>
                <li>
                  <span>1.1.12</span>
                  <p>
                  <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}>“Prescribed Purpose”</strong> or <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}>“Enquiry Reason”</strong> shall mean those reasons for which Consumer Credit Information may be
obtained as set out in the NCA and Regulations as amended from time to time;
                  </p>
                </li>
                <li>
                  <span>1.1.13</span>
                  <p>
                  <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}>“Primary Contact”</strong> refers to an individual appointed by the Subscriber to act as their representative with Accountability;
                  </p>
                </li>
                <li>
                  <span>1.1.14</span>
                  <p>
                  <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}>“Regulations”</strong> refers to the regulations promulgated in terms of the National Credit Act No. 34 of 2005, as amended;
                  </p>
                </li>
                <li>
                  <span>1.1.15</span>
                  <p>
                  <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}>“Subscriber”</strong> refers to a member, whether a legal entity or an individual, who has completed the registration process
and/or uses Accountability’s website. This term also includes the Subscriber’s agents, employees, and assigns.
                  </p>
                </li>
                <li>
                  <span>1.1.16</span>
                  <p>
                  In accordance with Section 11(2) and Section 11(3) of the ECT Act, <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}>“Terms and Conditions”</strong> or <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}>“Agreement”</strong> refers to these
Subscriber Terms and Conditions of Use, as amended from time to time, and includes any other terms and conditions
referenced within these Subscriber Terms and Conditions of Use, including those in separate documents or electronic
references;
                  </p>
                </li>
                <li>
                  <span>1.1.17</span>
                  <p>
                  <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}>“Transaction”</strong> refers to each instance in which a Subscriber accesses a specific service offered on the Accountability
website. For clarity, repeated access to the same service constitutes multiple transactions;
                  </p>
                </li>
                <li>
                  <span>1.1.18</span>
                  <p>
                  <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}>“User”</strong> refers to any individual who is authorized by the Subscriber to access Accountability’s services;
                  </p>
                </li>
                <li>
                  <span>1.1.19</span>
                  <p>
                  <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}>“Username and Password”</strong> refer to a unique combination of alphanumeric characters assigned to each User, intended
to provide secure access to Accountability Services while ensuring that usage can be traced to the respective User;
                  </p>
                </li>
                <li>
                  <span>1.1.20</span>
                  <p>
                  <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}>“Website”</strong> refers to http://www.accountability.co.za, including any derivatives thereof.
                  </p>
                </li>
                
              </ul>
              <h2 className="fontPurple">Preamble</h2>
              <ul className="terms-list">
                <li>
                  <span>2.1</span>
                  <p>
                  Accountability provides its services to Subscribers for consideration, as outlined in these Terms and Conditions.
                  </p>
                </li>
                <li>
                  <span>2.2</span>
                  <p>
                  The Subscriber hereby appoints Accountability as its agent to obtain Accountability’s services and agrees to abide by these
Terms and Conditions.
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">Appointment</h2>
              <ul className="terms-list">
                <li>
                  <span>3.1</span>
                  <p>
                  By completing the registration process and clicking “submit” on the registration page, which incorporates these Terms and
Conditions, the Subscriber appoints Accountability as its agent to procure Accountability’s services. By providing the primary
contact with a username and password, Accountability accepts this appointment.
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">Commencement and Duration</h2>
              <ul className="terms-list">
                <li>
                  <span>4.1</span>
                  <p>
                  Membership is subject to approval following the completion of the due diligence process. Upon receipt of a membership
application, a full credit report will be requested for the applicant and/or the company, with the cost of the report being for the
applicant’s account. Membership will not be activated until all required contractual documentation has been received and the
first month’s subscription payment is reflected on Accountability’s bank statement. In the event that a membership application
is rejected, the amount paid for the first month’s subscription is non-refundable.
                  </p>
                </li>
                <li>
                  <span>4.2</span>
                  <p>
                  The Agreement shall commence on the date that the account is
activated upon receipt and verification of the first month’s
membership fee ("the commencement date") and shall continue
thereafter for a period of one year. Thereafter, this Agreement shall
automatically be renewed on each anniversary of the commencement
date for periods of not less than one year each, unless terminated by
the Subscriber by giving Accountability one month’s written notice to
that effect, provided that such notice is received by Accountability not
less than one month prior to the next anniversary of the
commencement date, subject to the provisions of Section 14 of The
Consumer Protection Act No. 68, 2009, if applicable. Should membership be
terminated prematurely by the member, for whatever reason,
Accountability will be entitled to levy on such member a cancellation
fee equal to the balance of that particular year’s contractual
obligations. Annual price increases will be applied every year in March.
                  </p>
                </li>
                <li>
                  <span>4.3</span>
                  <p>
                  In the event that a Subscriber terminates their membership with Accountability and subsequently receives payment from a
debtor they had previously listed as a default payer with any Credit Bureau, the former Subscriber retains the legal obligation
under Section 71A of the NCA to update the relevant credit record. However, as the former Subscriber’s membership has been
terminated, Accountability may not accept instructions from the former Subscriber unless they engage Accountability’s services
on an ad-hoc basis. In such cases, a R750.00 (incl. VAT) administrative fee will be charged per instruction.
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">
                {" "}
                CANCELLATION PROCEDURES DURING COLLECTIONS PROCESS
              </h2>
              <ul className="terms-list">
                <li>
                  <p>
                  In the event that a Subscriber has submitted an instruction to appoint Accountability’s affiliated Debt Collector prior to submitting a
cancellation request, such cancellation will only take effect upon completion of the collections process, followed by an additional 60-
day period to allow for the finalization of invoicing and accounting procedures. Accounts with active payments will not be subject to
cancellation.
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">Fees</h2>
              <ul className="terms-list">
                <li>
                  <span>5.1</span>
                  <p>
                  Fees are charged in arrears each month with the exception of the first month’s membership fees, which incorporate an activation
fee equal to our monthly subscription fee.
                  </p>
                </li>
                <li>
                  <span>5.2</span>
                  <p>
                  Each transaction processed through the Accountability Group (Pty)Ltd and/or Accountability Solutions (Pty) Ltd websites will
incur a fee, which is payable upon receipt of the month-end statement, by any payment method specified on the Registration
Page.
                  </p>
                </li>
                <li>
                  <span>5.3</span>
                  <p>
                  All transactional fees payable by the Subscriber for Accountability’s services are listed on the website and are incorporated herein
by reference.
                  </p>
                </li>
                <li>
                  <span>5.4</span>
                  <p>
                  Accountability may, from time to time, increase the fees for its services and will provide the Subscriber with at least 14 (fourteen)
calendar days’ notice of any such increases. Notice of the increase may be provided electronically. Any fee increase will take effect
at the commencement of the new financial year, beginning on 1 March, subject to the 14-day notice period.
                  </p>
                </li>
                <li>
                  <span>5.5</span>
                  <p>
                  The Subscriber undertakes to pay all amounts due to Accountability, without deduction or set-off, on presentation of the monthend
statement provided by Accountability to the Subscriber.
                  </p>
                </li>
                <li>
                  <span>5.6</span>
                  <p>
                  The following provisions apply to the Subscriber:
                  </p>
                </li>
                <li>
                  <span>5.6.1</span>
                  <p>
                  if the payment tendered by the Subscriber is not honoured by the financial institution concerned, the Subscriber shall be
liable for an administration fee of <strong style={{fontWeight: 'bolder', fontFamily: 'montserratbold'}}>R240.33 (two hundred and forty rand and thirty-three cents) (incl. VAT)</strong> in addition
to the fees then due to Accountability; and
                  </p>
                </li>
                <li>
                  <span>5.6.2</span>
                  <p>
                  If the Subscriber fails to pay the amount due as specified in Clause 5.5, interest at the prevailing mora rate will be levied
on overdue amounts. This interest shall be calculated daily and compounded monthly.
                  </p>
                </li>
                <li>
                  <span>5.7</span>
                  <p>
                  If legal action has commenced, then the prescribed rate of interest in terms of the Prescribed Rate of Interest Act No. 55 of 1975
will apply.
                  </p>
                </li>
                <li>
                  <span>5.8</span>
                  <p>
                  In the event of a change in the rate of Value Added Tax (VAT) or any other charge that is imposed on the Accountability Services
by the South African Government or an organ thereof, Accountability shall be entitled to immediately adjust the transaction fee
accordingly.
                  </p>
                </li>
                <li>
                  <span>5.9</span>
                  <p>
                  The Subscriber consents to receiving invoices electronically. Invoices will be available for download from the website upon
logging in.
                  </p>
                </li>
                <li>
                  <span>5.10</span>
                  <p>
                  Accountability may, from time to time, run promotions. Promotional benefits, such as discounts or other incentives, will apply
only to Subscribers who sign up during the specified promotional period. Subscribers who join outside of a promotional period
will not be eligible for any discounts or adjustments to their monthly subscriptions.
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">
              Written Authority and Mandate for Debit Payment Instructions
              </h2>
              <ul className="terms-list">
                <li>
                  <span>6.1</span>
                  <p>
                  The signed Authority and Mandate refers to the contract entered into on the date of registration and submission of signed
registration documents. (“The Agreement”).
                    <br />
                    <br />
                    The Subscriber hereby authorises Accountability to issue and deliver payment instructions to the banker for collection against
the Subscriber’s account at their bank (or any other bank or branch to which the account may be transferred). This authorisation
is conditional upon the sum of such payment instructions never exceeding the Subscriber’s obligations as outlined in the
Agreement, which commences upon registration and continues until this Authority and Mandate is terminated by the Subscriber.
Termination requires written notice from the Subscriber, with no less than 20 ordinary working days’ notice. Such notice must
be sent via prepaid registered post, delivered to the provided address, or alternatively, emailed to the provided email address.
                    <br />
                    <br />
                    The individual payment instructions so authorised to be issued, must be issued and delivered monthly.
                    <br />
                    <br />
                    In the event that the payment day falls on a Sunday, or recognised South African public holiday, the payment day will
automatically be the very next ordinary business day. Furthermore, if there are insufficient funds in the Subscriber’s account
to meet the obligation, Accountability is entitled to track the account and represent the instructions for payment as soon as
sufficient funds are available in the Subscriber’s account.
                    <br />
                    <br />
                    The Subscriber understands that the withdrawals hereby authorised will be processed through a computerised system provided
by the South African Banks. The Subscriber also understands that details of each withdrawal will be displayed on their bank
statement. These details will include a reference number, which must be included in the payment instructions and, if provided
to the Subscriber, should enable the Subscriber to identify the Agreement. To further identify the debit collection, the short
names “ACCOUNTABI” and/or “ACCSOL” will be displayed on the bank statement.
                  </p>
                </li>
                <li>
                  <span>6.2</span>
                  <p>
                    Mandate <br />
                    <br />
                    The Subscriber acknowledges that all payment instructions issued by Accountability shall be treated by their aforementioned
bank as if the instructions have been issued by the Subscriber personally.
                  </p>
                </li>
                <li>
                  <span>6.3</span>
                  <p>
                    Cancellation
                    <br />
                    <br />
                    The Subscriber agrees that although this Authority and Mandate may be cancelled, such cancellation will not cancel the
Agreement. The Subscriber shall not be entitled to a refund of any amount withdrawn by Accountability while this Authority and
Mandate was in force, if such amount was legally owing to Accountability.
                  </p>
                </li>
                <li>
                  <span>6.4</span>
                  <p>
                    Assignment
                    <br />
                    <br />
                    The Subscriber acknowledges that this Authority and Mandate may be ceded or assigned to a third party if the Agreement is
also ceded or assigned to that third party. However, in the absence of such assignment of the Agreement, this Authority and
Mandate cannot be assigned to any third party.
                  </p>
                </li>
                <li>
                  <span>6.5</span>
                  <p>
                  Invoices for the monthly premium and services rendered will be collected by debit order, with deductions occurring no earlier
than the 25th of the month and no later than the last working day of that month. This arrangement will also apply to collections
during December.
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">Cooling-Off Period</h2>
              <ul className="terms-list">
                <li>
                  <span>7.1</span>
                  <p>
                  A cooling-off period for the cancellation of this Agreement will be determined in accordance with Section 44 of the ECT Act and
will be 5 (five) working days (excluding Saturdays, Sundays, and public holidays) from the date of conclusion of this contract.
Any services utilized by the Subscriber during the cooling-off period will be immediately due and payable. Failure to settle the
amount for utilized services may result in the default being reported to Credit Bureaus in accordance with Section 70 of the NCA,
No. 34 of 2005, as amended.
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">
                Submission and use of Data sent to Accountability
              </h2>
              <ul className="terms-list">
                <li>
                  <span>8.1</span>
                  <p>
                  The Subscriber undertakes to ensure that all data that is submitted by it and its Users contains the following information in
respect of a Consumer:
                  </p>
                </li>
                <li>
                  <span>8.1.1</span>
                  <p>initials and surname or full names and surname;</p>
                </li>
                <li>
                  <span>8.1.2</span>
                  <p>
                  South African identity number, or if the Consumer does not have an identity number, the passport number and date of
                  birth;
                  </p>
                </li>
                <li>
                  <span>8.1.3</span>
                  <p>
                  the residential address and telephone number of the Consumer (if known); and
                  </p>
                </li>
                <li>
                  <span>8.1.4</span>
                  <p>
                  the details of the employer and place of work of the Consumer (if known) and, if self-employed or unemployed, a
statement to that effect (if known).
                  </p>
                </li>
                <li>
                  <span>8.2</span>
                  <p>
                  The Subscriber undertakes to ensure that any data submitted to Accountability shall be accurate, up-to-date, relevant, complete,
and not duplicated.
                  </p>
                </li>
                <li>
                  <span>8.3</span>
                  <p>
                  The Subscriber undertakes that any data submitted to Accountability may be utilised by a Credit Bureau as part of its database
in the ordinary course of business of a Credit Bureau.
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">
                Use of Subscriber Data sent to Accountability
              </h2>
              <ul className="terms-list">
                <li>
                  <span>9.1</span>
                  <p>The Subscriber acknowledges and agrees that:</p>
                </li>
                <li>
                  <span>9.1.1</span>
                  <p>
                  The data contained in the Subscriber Application Form may be verified by Accountability in accordance with Section
72(2) of the NCA to ensure the accuracy thereof. This process will include obtaining a credit report, the cost of which will
be invoiced to the Subscriber;
                  </p>
                </li>
                <li>
                  <span>9.1.2</span>
                  <p>
                  Accountability may monitor the Subscriber’s payment behaviour by reviewing its profile with one or more Credit Bureaus.
                  </p>
                </li>
                <li>
                  <span>9.1.3</span>
                  <p>
                  Accountability may record and share details of the Subscriber’s performance under this Agreement with any Credit
Bureau.
                  </p>
                </li>
                <li>
                  <span>9.1.4</span>
                  <p>
                  Accountability may disclose information regarding the Subscriber’s use of Accountability’s Services to any Credit Bureau,
or as required by law or directed by the National Credit Regulator.
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">
                Technical Specifications and Security
              </h2>
              <ul className="terms-list">
                <li>
                  <span>10.1</span>
                  <p>
                  While Accountability strives to provide data related to the Accountability Services as promptly as possible, it cannot guarantee
the exact timeframe within which the data will be provided. If the required data has not been provided within 2 (two) working
days, please contact Accountability for assistance.
                  </p>
                </li>
                <li>
                  <span>10.2</span>
                  <p>
                  Accountability will provide Subscribers with technical specifications periodically to ensure the security of its system and related
systems, as well as for operational reasons. The Subscriber agrees to adhere to these technical specifications and implement
them promptly after they are released by Accountability. Failure to comply with these specifications may result in incorrect data
being received and/or submitted by the Subscriber and/or damages sustained by third parties, for which the Subscriber will be
held fully liable.
                  </p>
                </li>
                <li>
                  <span>10.3</span>
                  <p>
                  In addition to the technical specifications mentioned above, the Subscriber agrees to ensure that:
                  </p>
                </li>
                <li>
                  <span>10.3.1</span>
                  <p>
                  the username and password of each user are exclusively used by that particular user;
                  </p>
                </li>
                <li>
                  <span>10.3.2</span>
                  <p>
                  the username and password of any user are not disclosed to anyone other than the primary contact;
                  </p>
                </li>
                <li>
                  <span>10.3.3</span>
                  <p>
                  Accountability is notified as soon as possible if the Subscriber becomes aware of any security breach;
                  </p>
                </li>
                <li>
                  <span>10.3.4</span>
                  <p>
                  Accountability is notified either before or when the Primary Contact ceases to be entitled to represent the Subscriber;
                  </p>
                </li>
                <li>
                  <span>10.3.5</span>
                  <p>
                  the Subscriber will not tamper with, reverse-engineer, or disclose confidential information related to the security systems
of Accountability and/or any Credit Bureau, nor attempt to do so in any manner; and
                  </p>
                </li>
                <li>
                  <span>10.3.6</span>
                  <p>
                  the Subscriber is responsible for the actions of any users it has allowed access to Accountability services.
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">Intellectual Property</h2>
              <ul className="terms-list">
                <li>
                  <span>11.1</span>
                  <p>The Subscriber agrees that:</p>
                </li>
                <li>
                  <span>11.1.1</span>
                  <p>
                  no unauthorised use of Accountability’s and/or the applicable Credit Bureau’s intellectual property - which includes its
designs, trademarks, copyrighted works, or patents - is permitted; and
                  </p>
                </li>
                <li>
                  <span>11.1.2</span>
                  <p>
                  all rights, title, and interest in and to the Intellectual Property vests in the Credit Bureaus and/or Accountability, respectively,
                  as appropriate. Nothing in this Agreement transfers ownership in any of the Intellectual Property to any party.
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">Confidentiality and Privacy</h2>
              <p>The Privacy Policy is incorporated into and subject to these Terms and Conditions of Use, which are available on our website:
              https://www.accountability.co.za//</p>
              <ul className="terms-list">
                <li>
                  <span>12.1</span>
                  <p>
                  The parties warrant that they will not disclose Confidential Information to any unauthorized third party and will take all reasonable
measures to protect it in accordance with the provisions of the Protection of Personal Information Act, No. 4 of 2013.
                  </p>
                </li>
                <li>
                  <span>12.2</span>
                  <p>
                  In the event that any Confidential Information is disclosed, whether intentionally, negligently or otherwise, the party who
becomes aware of this disclosure will inform the other party in writing as soon as possible.
                  </p>
                </li>
                <li>
                  <span>12.3</span>
                  <p>
                  All payment information and personal information provided to Accountability is stored electronically and is only able to be
accessed by Accountability’s employees. If specifically required Accountability is obliged to provide personal data to a Credit
Bureau/Credit Regulator in order for the Credit Bureau/Credit Regulator to fulfill its functions in terms of the NCA. This information
is encrypted and protected by authentication mechanisms to ensure that this information is not disclosed to unauthorized
persons.
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">Warranties</h2>
              <ul className="terms-list">
                <li>
                  <span>13.1</span>
                  <p>
                  Except as expressly stated herein, neither Accountability nor any Credit Bureau makes any representations, warranties, or
guarantees of any kind, whether express, implied in law, or residual, in respect of this Agreement, the Accountability Services
and/or the accuracy or correctness of the reports and recommendations.
                  </p>
                </li>
                <li>
                  <span>13.2</span>
                  <p>The Subscriber hereby warrants that:</p>
                </li>
                <li>
                  <span>13.2.1</span>
                  <p>
                  It will comply with the terms of this Agreement and, in particular, will fulfill the commitments outlined herein.
                  </p>
                </li>
                <li>
                  <span>13.2.2</span>
                  <p>
                  It does not and will not, at any time, engage in or be directly or indirectly involved in the business of supplying, obtaining,
or requesting the removal of Consumer Credit Information from the relevant Credit Bureaus on behalf of any third party,
based on instructions received from its Consumer.
                  </p>
                </li>
                <li>
                  <span>13.2.3</span>
                  <p>
                  any data sent to Accountability is not subject to a duty of confidentiality between the Subscriber and the Consumer; and
                  </p>
                </li>
                <li>
                  <span>13.2.4</span>
                  <p>
                  it will at all times abide by all the applicable legal and/or regulatory requirements and constraints regarding its access to,
and use of, the Credit Bureaus’ information and services.
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">Indemnity</h2>
              <ul className="terms-list">
                <li>
                  <span>14.1</span>
                  <p>
                  The Subscriber hereby indemnifies Accountability and/or the applicable Credit Bureau from any claims of whatever nature
arising as a result of any actions taken or reliance made on information provided by Accountability and the Credit Bureau
concerned. Any instruction issued to either of the Accountability entities which results in litigation against Accountability will
be deemed to be the cause of damages, for which the Subscriber will be held fully liable. Such patrimonial damages shall not
be limited to legal fees only, but to all expenses which Accountability will incur in order to defend itself against such litigation. 
                  </p>
                </li>
                <li>
                  <span>14.2</span>
                  <p>
                  Without prejudice to any of the rights of Accountability or the applicable Credit Bureau at law or in terms of this Agreement,
the Subscriber indemnifies Accountability and the applicable Credit Bureau against all actual or contingent losses, liabilities,
damages, costs (including legal costs on the scale as between attorney and client and any additional legal costs) and expenses
of any nature whatsoever which Accountability and/or the applicable Credit Bureau may suffer or incur as a result of, or in
connection with the negligent acts or omissions of the Subscriber in carrying out its obligations in terms of this Agreement.
                  </p>
                </li>
                <li>
                  <span>14.3</span>
                  <p>Accountability and/or the applicable Credit Bureau will not be liable for any actual or contingent loss, liability, expense, costs or
                  damage of whatsoever nature (whether indirect, direct, consequential or otherwise) suffered by the Subscriber as a result of::</p>
                </li>
                <li>
                  <span>14.3.1</span>
                  <p>a breach of this Agreement by the Subscriber;</p>
                </li>
                <li>
                  <span>14.3.2</span>
                  <p>
                  the use of any data provided by Accountability after the termination of this Agreement;
                  </p>
                </li>
                <li>
                  <span>14.3.3</span>
                  <p>
                  any mistake, error or omission related to data submitted to or received from Accountability;
                  </p>
                </li>
                <li>
                  <span>14.3.4</span>
                  <p>
                  any delay or failure to communicate with Accountability; and
                  </p>
                </li>
                <li>
                  <span>14.3.5</span>
                  <p>an event of Force Majeure.</p>
                </li>
                
              </ul>
              <h2 className="fontPurple">Disclaimer</h2>
              <ul className="terms-list">
                <li>
                  <span>15.1</span>
                  <p>
                  All information provided to the Subscriber is provided on an “as is” basis and is sourced directly from Credit Bureaus’ databases.
Accountability does not have control over the data provided to the Subscriber and will not be held responsible for any error or
omission related to the data. In addition, Accountability does not warrant that the Accountability Services will be uninterrupted,
error-free, free of viruses or destructive code or that defects will be corrected.
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">Audit</h2>
              <ul className="terms-list">
                <li>
                  <span>16.1</span>
                  <p>
                  To ensure the Subscriber’s compliance with the terms of this Agreement, Accountability requires the Subscriber’s consent to
                  audit its books, records, and systems. The Subscriber hereby grants such consent.
                  </p>
                </li>
                <li>
                  <span>16.2</span>
                  <p>
                  Any audit of the Subscriber’s systems will be conducted solely to ensure compliance with the terms and conditions of this
Agreement. The information obtained during such audits will be kept confidential, unless disclosure is required to be made to
a Credit Bureau, the National Credit Regulator, in compliance with legal obligations, or by order of a court of law. Audits of the
Subscriber’s records will be limited to those records relevant to specific instructions issued to Accountability.
                  </p>
                </li>
                <li>
                  <span>16.3</span>
                  <p>
                  Accountability may appoint third parties as its representatives and/or agents to conduct the audit. The results of the audit will
be provided to the relevant Subscriber.
                  </p>
                </li>
                <li>
                  <span>16.4</span>
                  <p>
                  Accountability will cover the costs of the audit unless the audit reveals that the Subscriber is, or has been, in breach of this
                  Agreement. In the event of a breach, the Subscriber will be responsible for the full costs of the audit.
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">Force Majeure</h2>
              <ul className="terms-list">
                <li>
                  <span>17.1</span>
                  <p>
                  In the event that Accountability, the Subscriber, or the Credit Bureaus are unable to fulfill their obligations under this Agreement
due to Force Majeure, the affected party will be relieved of its obligations to the extent and for the duration of the Force Majeure
event.
                  </p>
                </li>
                <li>
                  <span>17.2</span>
                  <p>
                  Any party affected by Force Majeure, as described above, shall make diligent efforts to notify the other parties at the earliest
opportunity, providing details of the event causing the Force Majeure, its anticipated duration, and, subsequently, when the
Force Majeure no longer applies.
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">Cession</h2>
              <ul className="terms-list">
                <li>
                  <span>18.1</span>
                  <p>
                  The Subscriber shall not be entitled to cede or assign any of the rights contained herein without the express written permission
of one of the Directors of Accountability. Accountability may cede this Agreement by providing written notice to the Subscriber
which notice may be communicated electronically.
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">Legal Costs</h2>
              <ul className="terms-list">
                <li>
                  <span>19.1</span>
                  <p>
                  In the event that the Subscriber breaches this Agreement, it shall be liable for any legal, professional, and/or other costs and
disbursements (including the costs of any debtor notice, listings, tracing agent fees, and third-party collection commissions)
incurred as a result of the breach, calculated on the scale as between attorney and client.
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">Termination</h2>
              <ul className="terms-list">
                <li>
                  <span>20.1</span>
                  <p>
                  This Agreement may be terminated by either the Subscriber or Accountability by providing the other party with 30 (thirty)
calendar days’ notice of the intention to terminate, provided that the Subscriber has fulfilled its obligations as outlined in clause
4.2 above, or in the event of a breach of this Agreement as described herein.
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">Breach of Agreement</h2>
              <ul className="terms-list">
                <li>
                  <span>21.1</span>
                  <p>
                  In the event that the Subscriber breach any term of this Agreement, Accountability will be entitled to cancel this Agreement
immediately by notice in writing, without prejudice to any of its other rights and remedies, which include any right to claim
damages and indemnification.
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">Entire Agreement and Variation</h2>
              <ul className="terms-list">
                <li>
                  <span>22.1</span>
                  <p>
                  This Agreement constitutes the entire Agreement between Accountability and the Subscriber and supersedes any express or
implied oral representation or any advertising by a party. No variation of this Agreement is valid unless reduced to writing and
agreed to by a Director of Accountability. The signatory page, signed by the Subscriber on the date of the contract, forms an
integral part of this Agreement and must be read in conjunction therewith.
                  </p>
                </li>
                <li>
                  <span>22.2</span>
                  <p>
                  Any certificate issued by a Director of Accountability, whose appointment, qualification, and authority need not be proven,
indicating the various versions of this Agreement, shall serve as prima facie evidence of the current and previous versions. The
latest version of this Agreement will be made available to the Subscriber via the website.
                  </p>
                </li>
                <li>
                  <span>22.3</span>
                  <p>
                  Notwithstanding Clause 22.1 above, this Agreement may be amended by Accountability posting an updated version on the
website. Such amendment shall be deemed effective and binding on the parties as of the date specified in the amended
Agreement, provided that this date is no earlier than 5 (five) calendar days after the amended Agreement is posted on the
website. In the event of any conflict between the terms of the current version and previous versions of the Agreement as posted
on the website, the terms of the current version shall prevail.
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">
                Waiver and Failure to Enforce Rights
              </h2>
              <ul className="terms-list">
                <li>
                  <span>23.1</span>
                  <p>
                  No extension of time or indulgence granted by either party to the other shall be deemed in any way to affect, prejudice or
derogate from the rights of such party in any respect under this Agreement, nor shall it in any way be regarded as a waiver of
any rights hereunder, or a novation of this Agreemen
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">Governing Law</h2>
              <ul className="terms-list">
                <li>
                  <span>24.1</span>
                  <p>
                  This Agreement shall be construed and governed in accordance with the laws of the Republic of South Africa. The parties hereby
submit to the non-exclusive jurisdiction of the High Court of South Africa, Western Cape Division, Cape Town.
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">General</h2>
              <ul className="terms-list">
                <li>
                  <span>25.1</span>
                  <p>
                  The parties undertake to ensure that all necessary resolutions are passed and all documents are signed as is necessary to give
proper and due effect to the terms of this Agreement, or any matter arising as a result, according to its intent and purpose.
                  </p>
                </li>
                <li>
                  <span>25.2</span>
                  <p>
                  The expiration or termination of this Agreement shall not affect any provisions that expressly provide for their operation after
such expiration or termination, or those that, by their nature, must continue to have effect thereafter, even if not expressly stated
in the clauses themselves.
                  </p>
                </li>
                <li>
                  <span>25.3</span>
                  <p>
                  If any provision of this Agreement is unenforceable or invalid under applicable law, the remainder of that provision and the
remaining provisions of this Agreement shall remain binding and in full force and effect, provided that the essential economic
benefits of this Agreement are preserved.
                  </p>
                </li>
              </ul>
              <h2 className="fontPurple">Contact Information</h2>
              <ul className="terms-list">
                <li>
                  <span>26.1</span>
                  <p>
                  The parties designate their contact information and domicilium citandi et executandi as follows:
                  </p>
                </li>
                <li>
                  <span>26.1.1</span>
                  <p>The Subscriber – as provided in the Registration Form;</p>
                </li>
                <li>
                  <span>26.1.2</span>
                  <p>
                  Accountability – as set out in Clause 1.1.1 of the Terms and Conditions of Use and on the website. In the event of a
discrepancy between these details, the details on the website shall prevail. Either party may vary its domicilium citandi et
executandi by providing written notice to the other, which may be provided electronically.
                  </p>
                </li>
                <li>
                  <span>26.1.3</span>
                  <p>
                  Notwithstanding Clause 26.1 Accountability may require that the Subscriber authenticate this change in domicilium
citandi et executandi and contact information by providing proof of the new Subscriber address.
                  </p>
                </li>
              </ul>
              <p>
              Please note that by accepting these Terms you agree to the Terms and Conditions of the various providers.
              </p>
              {/* <p>
                <a
                  href="https://www.transunion.co.za/legal/terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  TransUnion Credit Bureau Terms &amp; Conditions
                </a>
              </p> */}
              <p>
              Accountability Subscriber Terms and Conditions Version 6.0 - Effective March 2025
              </p>
            </GridItem>
          </Grid>

          <ButtonList>
            <Button
              type="download"
              text="DOWNLOAD"
              theme="green"
              filePath="/assets/pdf/Terms and Conditions .pdf"
            />
          </ButtonList>
        </Container>
      </Section>
    </div>
  );
}

export default TermsAndConditions;
