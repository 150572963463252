import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import { fetch4In1Token } from "../../../actions/index";
import {
  renderInput,
  renderInputWithLabel,
  renderSelect,
  renderCheckbox
} from "../renderField";
import Button from "../../ui/button/Button";
import CenteredBox from "../../ui/structure/CenteredBox";
import Section from "../../ui/section/Section";
import SectionHeader from "../../ui/section/SectionHeader";
import Container from "../../ui/container/Container";
import FormContainer from "../../ui/container/form-container/FormContainer";
import Text from "../../ui/text/Text";
import Recaptcha from "react-recaptcha";
import Spacer from "../../ui/structure/spacer/Spacer";
import Loader from "../../ui/loader/Loader";
import scrollToFirstError from '../SmoothScrollToErrorFields.js';

class ReportRegisterFirstPage extends Component {

  constructor(props){
    super(props)
    this.verifyCallbackRecapcha = this.verifyCallbackRecapcha.bind(this);
    this.onloadCallbackRecapcha = this.onloadCallbackRecapcha.bind(this);
    this.state = {
      recapchaIsVerified: false
    }
  }  

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onloadCallbackRecapcha(){
    console.log('recapcha has loaded');
  }

  verifyCallbackRecapcha(response){
    if(response){
      this.setState({
        recapchaIsVerified: true
      }); 
    }      
    console.log('recapcha has been verified');
  }

  onSubmit = formValues => {
    if(this.state.recapchaIsVerified){
      this.props.fetch4In1Token(formValues, this.props.nextPage);
      window.scrollTo(0, 0);
    } else {
      console.log('recapcha has not been verified');
      alert('Please verify that you are human!');
    }
  };

  render() {
    const { handleSubmit } = this.props;
    const genderOptions = ["Please Select", "Male", "Female"];

    if (this.props.isLoading) {
      return <Loader halfscreen />;
    }

    return (
      <React.Fragment>
        <Container>
          <Section id="section-register">
            <SectionHeader noPadding autoWidth title="Registration" />
            <Text
              type="p"
              className="centered"
              text="The Comprehensive 4-in-1 Consumer Credit Report combines all your credit information retained by the four major South African based Credit Bureaus namely TransUnion, Experian, XDS and VeriCred."
            />
            <Text
              type="p"
              className="centered"
              text="The cost per report is <span class='color-green bold'>R259.74</span> (incl. VAT)."
            />
          </Section>
        </Container>

        <Section>
          <SectionHeader noPadding autoWidth title="Search Details" />

          <form onSubmit={handleSubmit(this.onSubmit)}>
            <FormContainer>
              {'public_4in1_firstname' && <div name={`position-public_4in1_firstname`} />}
              <Field
                name="public_4in1_firstname"
                type="text"
                component={renderInput}
                label="First Name *"
              />
              {'public_4in1_surname' && <div name={`position-public_4in1_surname`} />}
              <Field
                name="public_4in1_surname"
                type="text"
                component={renderInput}
                label="Surname *"
              />
              {'public_4in1_idnumber' && <div name={`position-public_4in1_idnumber`} />}
              <Field
                name="public_4in1_idnumber"
                type="number"
                component={renderInput}
                nomargin={true}
                label="ID Number *"
              />

              <div style={{ marginTop: "-10px" }}>
                <Text
                  type="p"
                  className="x-small no-padding"
                  text="Please use a VALID SOUTH AFRICAN ID number."
                />
                <Text
                  type="p"
                  className="x-small padding-small"
                  text="Please avoid using special characters (\ / ; : # -) or spaces."
                />
              </div>
              {'public_4in1_dateofbirth' && <div name={`position-public_4in1_dateofbirth`} />}
              <Field
                name="public_4in1_dateofbirth"
                type="date"
                component={renderInputWithLabel}
                label="Date Of Birth *"
              />
              {'public_4in1_gender' && <div name={`position-public_4in1_gender`} />}
              <Field
                name="public_4in1_gender"
                type="text"
                component={renderSelect}
                label="Gender"
                options={genderOptions}
              />
              {'public_4in1_cell_no' && <div name={`position-public_4in1_cell_no`} />}
              <Field
                name="public_4in1_cell_no"
                type="number"
                component={renderInput}
                label="Mobile Number *"
              />
              {'public_4in1_work_no' && <div name={`position-public_4in1_work_no`} />}
              <Field
                name="public_4in1_work_no"
                type="number"
                component={renderInput}
                label="Work Number *"
              />
              {'public_4in1_email' && <div name={`position-public_4in1_email`} />}
              <Field
                name="public_4in1_email"
                type="email"
                component={renderInput}
                label="Email Address *"
              />
              {'email_address_confirmation' && <div name={`position-email_address_confirmation`} />}
              <Field
                name="email_address_confirmation"
                type="email"
                component={renderInput}
                label="Confirm Email Address *"
              />
            </FormContainer>

            <Container>
              <Spacer size="120" />
              <article>
                <Text type="h3" className="default" text="Please Note:" />
                <Text
                  type="p"
                  text="For each successful enquiry, a fee of <b>R259.74 (incl. VAT)</b> will apply."
                />
                <Text
                    type="p"
                    text="Exclusively for Accountability Members, our comprehensive report is now available at a discounted rate of <b>R155.92 (incl. VAT).</b>"
                />
                 <Text
                    type="p"
                    text="This essential tool equips your business with the critical information needed to perform thorough consumer credit checks—ensuring due diligence at an affordable cost."
                />
                <Text
                    type="p"
                    text="Not a Member Yet?<br/>Join Accountability today to unlock this exclusive rate and elevate your credit vetting process."
                />
                <Button
                    type="redirect"
                    text="REGISTER"
                    theme="green"
                    url="/register"
                ></Button>
                <Spacer size="20" />
                <Text
                  type="p"
                  text="This report contains information supplied by the
                  Credit Bureaus that Accountability Group (Pty) Ltd does not have
                  control of and that has not been verified unless otherwise indicated in
                  the issued report. The report is subject to the Terms and Conditions
                  of Accountability Group (Pty) Ltd which can be found <a style='text-decoration: underline' href='/terms-and-conditions' target='_blank'>here.</a>"
                />
                <Text
                  type="p"
                  text="The fields marked with a * are mandatory and you will not be able to
                  perform the search without them."
                />
                <Text type="h3" className="default" text="ID Numbers:" />
                <Text
                  type="p"
                  text="Please use a VALID SOUTH AFRICAN ID number in the ID number field."
                />
                <Text
                  type="h3"
                  className="default"
                  text="Terms of Regulation:"
                />
                <Text
                  type="p"
                  text="In terms of Regulation 18(5) of the National Credit Regulations, the written consent of a
                   Consumer is required before such Consumer’s credit information may be accessed. 
                   You are cautioned that, before submitting an instruction for the generation of a Consumer report of any nature, the written consent of the Consumer has to been obtained, authorising you to do so."

                    
                />
                <Text
                  type="p"
                  text="By clicking on the “SUBMIT” button, you confirm that written
                  consent from the Consumer has been obtained and that a copy thereof will be available 
                  to Accountability Group (Pty) Ltd upon request within 48 hours of such request having
                   been made."
                />

                <Text
                  type="p"
                  text=" By submitting this request, you ensure that the above information
                  is true and correct and by clicking on the “SUBMIT” button you
                  deem this submission to be the approval for Accountability Group
                  (Pty) Ltd to proceed with the request."
                />
                {'agree_terms' && <div name={`position-agree_terms`} />}
                <Field
                  name="agree_terms"
                  type="checkbox"
                  component={renderCheckbox}
                  label="I agree to the <span style='color: #43358E; font-family: montserratbold;'>Terms of Regulation</span> as stated above *"
                  noMargin
                />
              </article>

              <Spacer size="60" />
              <CenteredBox noPadding stacked>
                <Recaptcha 
                  sitekey="6LfL17kUAAAAAOLB-BxODBlF9tIQCpESslxA_lLj"
                  render="explicit"
                  onloadCallback={this.onloadCallbackRecapcha}
                  verifyCallback={this.verifyCallbackRecapcha}
                />
                <Button
                  type="submit"
                  classNames={["next", "centered", "large"]}
                  theme="green"
                  text="NEXT"
                >
                  Next
                </Button>
              </CenteredBox>
            </Container>
          </form>
        </Section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.isLoading
  };
};

const formWrapped = reduxForm({
  form: "wizard", // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(ReportRegisterFirstPage);

export default connect(mapStateToProps, { fetch4In1Token })(formWrapped);
